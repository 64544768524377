import React from 'react';
import{
  Select,
  MenuItem,
  InputBase,
  Typography,
  Divider
} from '@material-ui/core';

export default function GroupSelector(props){

  const{
    card,
    updateGroup,
    groups,
    setNewGroupOpen,
    profile
  } = props;

  const value = card.group;

  const handleChange=(ev)=>{
    if(ev.target.value === null){
      setNewGroupOpen(true);
    }else{
      updateGroup(ev.target.value);
    }
  }

  return(
    <div>
      <Select
      value={value}
      onChange={handleChange}
      input={<InputBase/>}
      disabled={!profile}
      >
      {
        groups.map((group,index)=>
          <MenuItem value={group.title} key={index}>
          {group.title}
          </MenuItem>
        )
      }
      <Divider/>
      <MenuItem value={null} key={'add'}>
      + New Category
      </MenuItem>
      </Select>
    </div>
  )
}
