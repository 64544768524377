import React, {useState} from 'react';
import {
  Typography,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import {
  MoreVert
} from '@material-ui/icons';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
  link:{
    textDecoration:'none',
    color:'black'
  },
}))

export default function CardMenu(props){

  const classes = useStyles();
  const [anchor,setAnchor] = useState(null);

  const{
    card,
    deleteCard
  } = props;

  const handleDelete=()=>{
    setAnchor(null);
    deleteCard(card.uid);
  }

  return(
    <div>
      <IconButton onClick={(ev)=>{setAnchor(ev.currentTarget)}}>
        <MoreVert/>
      </IconButton>
      <Menu
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={()=>{setAnchor(null)}}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Link to={'/'} className={classes.link}>
          <MenuItem onClick={handleDelete}>
          Delete Card
          </MenuItem>
        </Link>
      </Menu>
    </div>
  )

}
