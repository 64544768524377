import React, {useState, useRef, useEffect} from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import SpriteText from 'three-spritetext';
import {Redirect} from 'react-router-dom';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import BlockRightDrawer from './BlockRightDrawer';
import FilterRightDrawer from './FilterRightDrawer';
import {
  Button,
  IconButton,
  Typography
} from '@material-ui/core';
import SearchDialog from '../main/SearchDialog';
import { makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme)=>({
  searchButton:{
    position:'absolute',top:10,left:10,color:theme.palette.primary.main,
    border:'1px solid ',
    borderColor: theme.palette.primary.main
  },
  typeToggle:{
    position:'absolute',top:10,right:400,
    border:'1px solid black',
    color:'black'
  },
  filterButton:{
    position:'absolute',top:15,right:300,
    border:'1px solid black',
    color:'black'
  }
}));

export default function Edit2DGraphView(props){

  const classes = useStyles();

  const [selectedNode,setSelectedNode] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);

  const graphRef = useRef();

  const workspace = useSelector(state => state.user.workspace);
  const cards = useSelector(state => state.data.cards);
  const links = useSelector(state => state.data.links);
  const data = useSelector(state => state.data.data);

  const groups = workspace ? workspace.categories : [];

  const{
    handleRedirect,
    redirect,
    db,
    searchOpen,
    setSearchOpen,
    filterOpen,
    setFilterOpen,
    updateCategories
  } = props;

  const focusOnNode=(node)=>{
    graphRef.current.centerAt(node.x,node.y,1000);
    graphRef.current.zoom(8,2000);
  }

  const handleClick = (node)=>{
    if(selectedNode){
      if(selectedNode.id === node.id){
        handleRedirect(selectedNode.id);
      }else{
        setSelectedNode(node);
        // addLink(selectedNode,node.id);
      }
    }else{
      setSelectedNode(node);
    }
  };

  const handleHover=(node,prevNode)=>{
    setHoveredNode(node);
  }

  const handleSearch=(nodeId)=>{
    const selected = data.nodes.find(node => node.id === nodeId);
    if(selected){
      focusOnNode(selected);
      setSelectedNode(selected);
    }
  }

  const handleBackgroundClick=async(event)=>{
    if(selectedNode){
      setSelectedNode(null);
      // const cardId = await addCard(selectedNode.id,false);
      // if(cardId){
      //   handleRedirect(cardId);
      // }
    }
  }

  const renderName=(node)=>{
    const sprite = new SpriteText(node.name);
    sprite.color = 'white';
    sprite.textHeight = 2 + Number(node.val*2);
    sprite.position.y = 8;
    return sprite;
  }

  const handleDragEnd=(node)=>{
    node.fx = node.x;
    node.fy = node.y;
    node.fz = node.z;
  }

  const handleColor=(node)=>{
    const match = groups.find(item => item.title === node.group);
    if(match){
      return match.color;
    }
    return 'white';
  }

  const handleCanvasObject=(node,ctx,globalScale)=>{

    const label = node.name;
    const fontSize = 12/globalScale;
    ctx.font = `${fontSize}px Sans-Serif`;
    const textWidth = ctx.measureText(label).width;
    const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
    ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
    //
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';
    ctx.fillText(label, node.x, node.y);
  }

  const handleLinkWidth=(link)=>{
    if(hoveredNode){
      if(link.source.id === hoveredNode.id || link.target.id === hoveredNode.id){
        return 3;
      }
    }
    return 1;
  }


  if(redirect){
    return(
      <Redirect exact to={'/card/'+redirect}/>
    )
  }

  if(data){
    return (
      <div style={{position:'relative'}}>
        <ForceGraph2D
          ref={graphRef}
          graphData={data}
          nodeColor={node=>handleColor(node)}
          onNodeClick={handleClick}
          onBackgroundClick={handleBackgroundClick}
          onNodeDragEnd={node => handleDragEnd(node)}
          nodeCanvasObject={(node,ctx,globalScale) => handleCanvasObject(node,ctx,globalScale)}
          nodeCanvasObjectMode={()=>'after'}
          linkDirectionalArrowLength={3}
          linkDirectionalArrowRelPos={.7}
          showNavInfo={false}
          onNodeClick={handleClick}
          onNodeHover={handleHover}
          linkWidth={handleLinkWidth}
        />
        {
          selectedNode &&
          <BlockRightDrawer card={selectedNode} setCard={setSelectedNode} links={links} cards={cards}/>
        }
        {
          filterOpen &&
          <FilterRightDrawer open={filterOpen} setOpen={setFilterOpen} updateCategories={updateCategories}/>
        }
        <SearchDialog open={searchOpen} setOpen={setSearchOpen} selectCard={handleSearch} cards={cards}/>
      </div>
    )
  }

  return(
    <div/>
  )

}
