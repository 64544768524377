import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './redux/rootReducer';
import ReduxThunk from 'redux-thunk'

const store = createStore(
	rootReducer,
	applyMiddleware(ReduxThunk)
);

ReactDOM.render(

    <Provider store={store}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </Provider>
,
  document.getElementById('root')
);

serviceWorker.unregister();
