import React, { Component } from 'react';
import {Route,Redirect,Switch,withRouter} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import {connect} from 'react-redux';
//firebase
import firebase from 'firebase';
import config from './firebase';
//Components
import SignInContainer from './signIn/SignInContainer';
import MainContainer from './main/MainContainer';
import GalleryContainer from './gallery/GalleryContainer';
//utils
import {v4 as uuidv4} from 'uuid';

import{
  setProfile,
  setAuthLoaded
} from './redux/userReducer';

firebase.initializeApp(config);
const db = firebase.firestore();
const settings = {};
db.settings(settings);

const theme = createMuiTheme({
  palette:{
    primary:{
      main: '#506CF0'
    },
    secondary:{
      main: '#32DAAB'
    },
    warning:{
      main: '#FF5A5F'
    }
  },
  padding:{
    desktop:'10%',
    mobile:'2%',
  },
  typography:{
    useNextVariants:true,
    fontFamily:"'Open Sans', sans-serif"
  }
});

class App extends Component{

  createProfile=async(user)=>{
    const userName = user.displayName.split(" ");
    const firstName = userName[0];
    const docRef = db.collection('Profiles').doc(user.uid);

    const workspaceId = uuidv4();
    const workspaceRef = db.collection('Workspaces').doc(workspaceId).set({
      name:'Tutorial',
      owner:user.uid,
      categories:[
        {
          color:'#dee3f2',
          enabled:true,
          title:'None'
        },
        {
          color:'#CFE5F9',
          enabled: true,
          title:'Tutorial'
        }
      ]
    });

    await docRef.set({
      uid:user.uid,
      firstName: firstName,
      email: user.email,
      created: new Date(),
      imageUrl: user.photoURL,
      currentWorkspace: workspaceId,
      graphType:'3d'
    });
    docRef.get().then(function(doc){
      if(doc.exists){
        this.props.setProfile(doc.data());
      }
    }.bind(this));
  }

  fetchProfile=async(user)=>{
    const unsubProfile = db.collection('Profiles').doc(user.uid).onSnapshot(function(doc){
      if(doc.exists){
        this.props.setProfile(doc.data())
        this.props.setAuthLoaded(true);
      }else{
        this.createProfile(user);
      }
    }.bind(this))
  }

  logOut=async()=>{
    firebase.auth().signOut();
    this.props.setProfile(null);
  }

  init=async()=>{
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async(user) =>{
        if(user){
          this.fetchProfile(user);
        }else{
          //not signed in
          this.props.setAuthLoaded(true);
        }
      }
    );
  }

  componentDidMount=async()=>{
      this.init();
  }

  componentWillUnmount=()=>{
    if(this.unsubProfile){
      this.unsubProfile();
    }
  }

  render(){

    return(
      <MuiThemeProvider theme={theme}>
        <Switch>
          //redirect to selected workspace if profile
          {
            this.props.profile ?
            <Redirect exact from='/' to={'/graph/'+this.props.profile.currentWorkspace}/>
            :
            <Redirect exact from='/' to='/signIn'/>
          }
          <Route exact path='/signIn' render={props=>(
            <SignInContainer {...props} firebase={firebase} db={db}/>
          )}/>
          <Route exact path='/:type/:id' render={props=>(
            <MainContainer
            {...props}
            logOut={this.logOut}
            db={db}
            />
          )}/>
          <Route exact path='/gallery' render={props=>(
            <GalleryContainer {...props} db={db} logOut={this.logOut}/>
          )}/>
        </Switch>
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    profile: state.user.profile
  }
}

const mapDispatchToProps={
  setProfile,
  setAuthLoaded
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App));

// <Route exact path='/card/:cardId' render={props=>(
//   <MainContainer
//   {...props}
//   logOut={this.logOut}
//   db={db}
//   />
// )}/>
