import React, {useState,useEffect} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  InputAdornment
} from '@material-ui/core';
import{
  Clear,
  Done
} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
  container:{
    display:'flex',
    flexDirection:'column'
  },
  input:{
    width:'200px'
  },
  actions:{
    display:'flex'
  }
}))

export default function NewGroupDialog(props){
  const classes = useStyles();

  const [title,setTitle] = useState('');
  const [color,setColor] = useState('');
  const [error,setError] = useState(null);

  const{
    open,
    setOpen,
    addGroup
  } = props;

  function isHexColor (hex) {
    return typeof hex === 'string'
        && hex.length === 6
        && !isNaN(Number('0x' + hex))
  }

  const handleSubmit=()=>{
    setError(null);
    if(isHexColor(color.substring(1))){
      addGroup(title,color);
      handleClose();
    }else{
      setError('Invalid Color');
    }
  }

  const handleClose=()=>{
    setTitle('');
    setColor('');
    setOpen(false);
  }

  return(
    <Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paper:classes.container
    }}
    >
    <DialogContent className={classes.container}>
      <TextField className={classes.input} value={title} onChange={(ev)=>{setTitle(ev.target.value)}} placeholder='Category' autoFocus/>
      <TextField className={classes.input} value={color} onChange={(ev)=>{setColor(ev.target.value)}} placeholder='#HTML color'/>
      <DialogActions>
        <IconButton onClick={handleClose}><Clear/></IconButton>
        <IconButton onClick={handleSubmit}><Done/></IconButton>
      </DialogActions>
      {
        error &&
        <Typography style={{color:'red'}}>{error}</Typography>
      }
    </DialogContent>
    </Dialog>
  )
}
