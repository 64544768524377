import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import emptyProfile from '../images/empty_profile.png';
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Button
} from '@material-ui/core';
import {Link} from 'react-router-dom';

export default function ProfileMenu(props){
  const [anchor,setAnchor] = useState(null);
  const profile = useSelector(state => state.user.profile);

  const handleLogOut=()=>{
    setAnchor(null);
    props.logOut();
  }

  const handleMenuOpen=(ev)=>{
    setAnchor(ev.currentTarget);
  }

  return(
    <div style={{marginLeft:'auto'}}>
    {
      profile ?
      <IconButton onClick={handleMenuOpen}>
        {(profile && profile.imageUrl)?
          <Avatar alt={emptyProfile} src={profile.imageUrl}/>
          :
          <Avatar src={emptyProfile} alt={emptyProfile}/>
        }
      </IconButton>
      :
      <Link to='/signin' style={{textDecoration:'none'}}>
        <Button
        variant='outlined'
        color='secondary'
        >
        Sign In to Edit
        </Button>
      </Link>
    }
    <Menu
    anchorEl={anchor}
    getContentAnchorEl={null}
    open={Boolean(anchor)}
    onClose={()=>{setAnchor(null)}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem onClick={handleLogOut}>
      Log Out
      </MenuItem>
    </Menu>
    </div>
  )
}
