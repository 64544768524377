const SET_DATA = 'SET_DATA';
const SET_CARDS = 'SET_CARDS';
const SET_LINKS = 'SET_LINKS';
const SET_COPIED_BLOCKS = 'SET_COPIED_BLOCKS';

export const setData =(data)=>{
  return{
    type: SET_DATA,
    data
  }
}

export const setCards = (cards)=>{
  return{
    type: SET_CARDS,
    cards
  }
}

export const setLinks = (links)=>{
  return{
    type: SET_LINKS,
    links
  }
}

export const setCopiedBlocks =(copiedBlocks)=>{
  return{
    type: SET_COPIED_BLOCKS,
    copiedBlocks
  }
}

const initialState={
  data: null,
  cards: [],
  links: [],
  copiedBlocks: []
}

const dataReducer=(state=initialState, action)=>{
  switch(action.type){
    case SET_DATA:
      return{
        ...state,
        data: action.data
      }
    case SET_CARDS:
      return{
        ...state,
        cards: action.cards
      }
    case SET_LINKS:
      return{
        ...state,
        links: action.links
      }
    case SET_COPIED_BLOCKS:
      return{
        ...state,
        copiedBlocks: action.copiedBlocks
      }
    default:
      return state
  }
}

export default dataReducer;
