import React from 'react';
import {
  TextField,
  Dialog
} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import { makeStyles} from '@material-ui/core/styles';

const filter = createFilterOptions();

const useStyles = makeStyles((theme)=>({
  container:{
    padding:theme.spacing(1),
  },
  autoComplete:{
    width:'300px'
  }
}))

export default function InternalLinkPopover(props){
  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  const{
    anchor,
    setAnchor,
    addLink,
    cards,
    addCard
  } = props;

  const handleClose=()=>{
    setAnchor(null);
  }

  const handleChange=(event,newValue)=>{
    if(!newValue.id){
      addCard(newValue.inputValue);
    }else{
      addLink(newValue.id,newValue.name);
    }
    handleClose();
  }

  const filterOptions=(options,params)=>{
    const filtered = filter(options, params);
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
        id:null
      });
    }
    return filtered;
  }

  return(
    <Dialog
    open={Boolean(anchor)}
    onClose={handleClose}
    classes={{
      paper:classes.container
    }}
    >
      <Autocomplete
      freeSolo
      autoHighlight
      openOnFocus
      value={value}
      options={cards}
      filterOptions={filterOptions}
      getOptionLabel={(option)=>option.name}
      className={classes.autoComplete}
      onChange={handleChange}
      renderInput={(params) => <TextField autoFocus{...params} />}
      />
    </Dialog>
  )
}
