import React from 'react';
import {
  TextField,
  Dialog,
  ListItem,
  ListItemText,
  List,
  Slide,
  IconButton,
  AppBar,
  Toolbar,
  Typography
} from '@material-ui/core';
import{
  Clear
} from '@material-ui/icons';
import { makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from '@material-ui/lab';

const useStyles = makeStyles((theme)=>({
  appBar:{

  },
  clear:{
    marginLeft:'auto'
  },
  options:{
    marginTop:'60px'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MobileBlockOptions(props){
  const classes = useStyles();

  const{
    open,
    setMobileMenu,
    handleMenuSelection,
    setSelectedIndex
  } = props;

  const handleChange=(option,el)=>{
    handleMenuSelection(option.value,el);
    setMobileMenu(false);
  }

  const handleClose=()=>{
    setMobileMenu(false);
    setSelectedIndex(false);
  }

  const options=[
    {
      label:'H1: Header 1',
      value:'h1'
    },
    {
      label:'H2: Header 2',
      value:'h2'
    },
    {
      label:'Bold',
      value:'bold'
    },
    {
      label:'Internal Link',
      value:'internal'
    },
    {
      label:'External URL',
      value:'url'
    },
  ]

  return(
    <Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography>Options</Typography>
          <IconButton onClick={handleClose} className={classes.clear}>
            <Clear/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <List className={classes.options}>
      {
        options.map((option,index)=>
          <ListItem button onClick={(ev)=>{handleChange(option,ev.currentTarget)}} key={index}>
            <ListItemText primary={option.label}/>
          </ListItem>
        )
      }
      </List>
    </Dialog>
  )
}
