import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import ErrorPage from '../notify/ErrorPage';
import BlockEditView from './block/BlockEditView';

export default function EditCardContainer(props){

  const [card,setCard] = useState(null);
  const [error, setError] = useState(null);

  const{
    db,
    cardId,
    addCard,
    redirect,
    fetchCards,
    handleRightValue,
    rightValue,
    deleteCard,
    setSearchOpen,
    fetchWorkspace
  } = props;

  const profile = useSelector(state => state.user.profile);
  const currentWorkspace = useSelector(state => state.user.workspace);
  const cards = useSelector(state => state.data.cards);
  const links = useSelector(state => state.data.links);

  const groups = currentWorkspace ? currentWorkspace.categories : [];

  const checkPermissions=(card)=>{
    if(card.publicEdit){
      return true;
    }
    if(currentWorkspace && currentWorkspace.publicEdit){
      return true;
    }
    if(profile){
      if(card.owner === profile.uid){
        return true;
      }else if(currentWorkspace && currentWorkspace.owner === profile.uid){
        return true;
      }else if(currentWorkspace && currentWorkspace.collaborators && currentWorkspace.collaborators.includes(profile.uid)){
        return true;
      }else if(card.collaborators && card.collaborators.contains(profile.uid)){
        return true;
      }
    }
    return false;
  }

  useEffect(()=>{
    let unsubCard = null;

    const fetchCard=async(cardId)=>{
      unsubCard = db.collection('Cards').doc(cardId).onSnapshot(function(doc){
        if(doc.exists){
          const card = doc.data();
          card.uid = doc.id;
          card.id = doc.id;
          //overwrite backlinks
          card.backLinks = links.filter(link => link.target == card.uid)
          if(checkPermissions(card)){
            setCard(card);
            if(card.workSpace !== currentWorkspace.id){
              fetchWorkspace(card.workSpace);
            }
          }else{
            setError('You do not have permission to edit this card')
          }
        }else{
          setError('This card no longer exists');
        }
      })
    }

    const match = cards.find(card => card.id === cardId);
    if(match){
      setCard(match);
    }

    fetchCard(cardId);
    if(profile){
      db.collection('Cards').doc(cardId).update({
        lastViewed: Date.now()
      })
    }


    return function cleanUp(){
      if(unsubCard){
        unsubCard();
      }
      setError(null);
      setCard(null);
    }
  },[cardId,profile]);

  const updateName = async(value)=>{
    await db.collection('Cards').doc(card.uid).update({
      name:value,
      lastUpdated: Date.now()
    });
    fetchCards();
  }

  const updateCard = async(value,fetchFlag,links)=>{
    await db.collection('Cards').doc(cardId).update({
      content:value,
      lastUpdated: Date.now(),
      // links:links
    });
    if(fetchFlag){
      await fetchCards();
    }
    // updateBackLinks(links);
  }

  const updateBlocks = async(blocks,fetchFlag)=>{
    await db.collection('Cards').doc(cardId).update({
      blocks: blocks,
      lastUpdated: Date.now()
    });
    if(fetchFlag){
      await fetchCards();
    }
  }

  const updateGroup = async(group)=>{
    await db.collection('Cards').doc(card.uid).update({
      group:group,
      lastUpdated: Date.now()
    })
    fetchCards();
  }

  const addGroup=(title,color)=>{
    groups.push({
      title:title,
      color:color,
      enabled: true
    });
    db.collection('Workspaces').doc(currentWorkspace.id).update({
      categories:groups
    });
  }

  if(error){
    return(
      <ErrorPage error={error}/>
    )
  }

  if(card){
    const backLinks = links.filter(link => link.target === card.uid);

    return(
      <BlockEditView
      card={card}
      profile={profile}
      addCard={addCard}
      redirect={redirect}
      updateCard={updateCard}
      cards={cards}
      updateName={updateName}
      handleRightValue={handleRightValue}
      rightValue={rightValue}
      backLinks={backLinks}
      deleteCard={deleteCard}
      updateGroup={updateGroup}
      groups={groups}
      updateBlocks={updateBlocks}
      setSearchOpen={setSearchOpen}
      db={db}
      addGroup={addGroup}
      />
    )
  }

  return(
    <div>

    </div>
  )

}
