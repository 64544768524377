import React, {useState,useEffect,useRef} from 'react';
import {
  Typography,
  InputBase,
  Popover
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
const interval = process.env.REACT_APP_INTERVAL;

const useStyles = makeStyles((theme)=>({
  default:{

  },
  headerOne:{
    fontSize:'30px'
  },
  headerTwo:{
    fontSize:'22px'
  },
  bold:{
    fontWeight:'bold'
  }
}))

export default function TextBlock(
  {
    block,
    index,
    updateText,
    blockCount,
    addBlock,
    deleteBlock,
    setMenu,
    focus,
    setFocus,
    setSearchOpen,
    updateBlockType,
  }
){
  const classes = useStyles();
  let className = 'default';
  switch(block.type){
    case 'h1':
      className = 'headerOne';
      break;
    case 'h2':
      className = 'headerTwo';
      break;
    case 'bold':
      className = 'bold';
      break;
    default:
      className = 'default';
  }

  const [value,setValue] = useState(block.value ? block.value : '');
  const [anchor, setAnchor] = useState(null);

  const inputRef = useRef(null);

  useEffect(()=>{
    setValue(block.value);
    if(focus === index){
      inputRef.current.focus();
    }
    return function cleanUp(){
      setValue('');
    }
  },[block.id,focus])

  const handleChange=(ev)=>{
    const newValue = ev.target.value;
    setValue(newValue);
    clearTimeout(window.textBlockTimeOut);
    window.textBlockTimeOut = setTimeout(function(){
      updateText(newValue,index);
    },interval);
  }

  const handleKeyDown=(ev)=>{
    if(ev.key === 'Enter' || ev.keyCode === '13'){
      ev.preventDefault();
      clearTimeout(window.textBlockTimeOut);
      updateText(value,index);
      if(value === ''){
        addBlock(index+1);
      }else if(ev.target.selectionStart === 0){
        addBlock(index);
      }else{
        addBlock(index+1);
      }
    }else if(ev.key === 'Backspace' || ev.keyCode === '8'){
      if(blockCount >1 && !value){
        ev.preventDefault();
        clearTimeout(window.textBlockTimeOut);
        updateText(value,index);
        deleteBlock(index);
      }
    }else if((ev.key === '/' || ev.keyCode === '9') && !value){
      ev.preventDefault();
      setMenu(ev.currentTarget,index);
    }else if(ev.key === 'ArrowUp' || ev.keyCode === '38'){
      ev.preventDefault();
      setFocus(index -1);
    }else if (ev.key === 'ArrowDown' || ev.keyCode === '40'){
      ev.preventDefault();
      setFocus(index +1);
    }else if(ev.ctrlKey && (ev.key === 's' || ev.keyCode === '83')){
      ev.preventDefault();
      setSearchOpen(true);
    }else if(ev.metaKey && (ev.key === 'b' || ev.keyCode === '66')){
      ev.preventDefault();
      let type = 'bold';
      if(block.type === 'bold'){
        type = 'text';
      }
      updateBlockType(type,index);
    }else if(ev.keyCode ==='32' || ev.key=== ' '){
      if(value ==='#'){
        ev.preventDefault();
        updateBlockType('h1',index);
        setValue('');
      }else if(value === '##'){
        ev.preventDefault();
        updateBlockType('h2',index);
        setValue('');
      }
    }
  }

  const handleMouseUp=(ev)=>{
    // const selection = window.getSelection();
    // if(selection.type === 'Range'){
    //   console.log(selection.toString())
    //   setAnchor({
    //     title:selection.toString(),
    //     x:ev.clientX,
    //     y:ev.clientY
    //   })
    // }
  }

  const handleFocus =()=>{
    if(focus !== index){
      setFocus(index);
    }
  }

  const isFocused = focus === index;

  return(
    <InputBase
    inputRef={inputRef}
    className={classes[className]}
    value={value}
    onChange={handleChange}
    onKeyDown={handleKeyDown}
    placeholder={isFocused ? 'Press / for options' : ''}
    multiline
    onFocus={handleFocus}
    onMouseUp={handleMouseUp}
    />
  )
}
