import React from 'react';
import {
  Button,
  IconButton,
  Typography,
  Hidden,
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
  container:{
    backgroundColor:'#1f2227',
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'10px'
  },
  container2d:{
    backgroundColor:'white',
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'10px'
  },
  searchButton:{
    border:'1px solid white',
    color:'white'
  },
  search2dButton:{
    border:'1px solid',
  },
  type2d:{
    border:'1px solid white',
    color:'white',
    marginRight:'10px'
  },
  type3d:{
    border:'1px solid',
    marginRight:'10px'
  },
  filterButton:{
    border:'1px solid white',
    color:'white'
  },
  filter2dButton:{
    border:'1px solid'
  },
  openRightActions:{
    [theme.breakpoints.down('xs')]:{
      marginRight:'20px'
    },
    [theme.breakpoints.up('sm')]:{
      marginRight:'320px'
    }
  },
  closedRightActions:{
    marginRight:'60px'
  }
}))

export default function GraphControls(props){

  const classes = useStyles();

  const {
    open,
    setSearchOpen,
    graphType,
    handleGraphType,
    setFilterOpen,
    profile
  } = props;

  return(
    <div className={graphType === '3d' ? classes.container: classes.container2d}>
      <div id='left'>
        <Button
        className={graphType === '3d' ? classes.searchButton : classes.search2dButton}
        disableRipple
        onClick={(ev)=>{
          setSearchOpen(true)
          }}
        >
        Search Graph
        </Button>
        <Hidden xsDown>
          <a href='https://forms.gle/N8wDrCJubRUPCMDFA' rel="noopener noreferrer" target="_blank" style={{textDecoration:'none'}}>
            <Button color='secondary' variant='outlined' style={{marginLeft:'20px'}}>Submit Feedback</Button>
          </a>
        </Hidden>
      </div>
      <div id='right' className={open ? classes.openRightActions : classes.closedRightActions}>
        <IconButton
        className={graphType === '3d' ? classes.type2d : classes.type3d}
        onClick={
          ()=>{handleGraphType(
            graphType === '3d' ? '2d' : '3d'
          )}
        }>
          <Typography>
          {graphType === '3d' ? '2D' : '3D'}
          </Typography>
        </IconButton>
        <Button
        className={graphType === '3d' ? classes.filterButton : classes.filter2dButton}
        onClick={
          ()=>{setFilterOpen(true)}
        }>
        Filter
        </Button>
      </div>
    </div>
  )
}
