import React, {useState, useRef, useEffect} from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  Button
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import EditCardContainer from '../editor/EditCardContainer';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
  container:{
    padding:theme.spacing(1),
  },
  link:{
    textDecoration:'none',
  },
}))

export default function CardDialog(props){
    const classes = useStyles();

    const{
      selectedNode,
      setSelectedNode,
      db,
      addCard,
      redirect,
      cards,
      fetchCards,
      links,
      deleteCard,
      groups
    } = props;

    const handleClose=()=>{
      setSelectedNode(null);
    }

    return(
      <Dialog
      open={Boolean(selectedNode)}
      onClose={handleClose}
      classes={{
        paper:classes.container
      }}
      >
        <div>
          <Link to={'/card/'+selectedNode.id} className={classes.link}>
            <Button variant='outlined'>
            Open
            </Button>
          </Link>
        </div>
        <EditCardContainer
        cardId={selectedNode.id}
        db={db}
        addCard={addCard}
        redirect={redirect}
        cards={cards}
        fetchCards={fetchCards}
        handleRightValue={()=>{}}
        rightValue={'none'}
        links={links}
        deleteCard={deleteCard}
        groups={groups}
        setSearchOpen={()=>{}}
        />
      </Dialog>
    )
}
