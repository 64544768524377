import React from 'react';
import{
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import {
  Clear
} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container:{

  },
  header:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    borderTop:'1px solid lightgrey'
  },
  headerText:{
    marginLeft: theme.spacing(1)
  },
  nullSection:{
    marginLeft: theme.spacing(1)
  },
  link:{
    textDecoration:'none',
    color:theme.palette.primary.main
  },
  list:{
    overflowY:'auto'
  }
}));

export default function BackLinkMenu(props){

  const classes = useStyles();

  const{
    handleRightValue,
    card,
    cards,
    backLinks
  } = props;

  let backCards = [];
  backLinks.forEach(link=>{
    const backCard = cards.find(card=> card.id === link.source);
    if(backCard){
      backCards.push(backCard);
    }
  })

  return(
    <div className={classes.container}>
      <section className={classes.header}>
        <Typography variant='h6' className={classes.headerText}>Back Links</Typography>
        <IconButton onClick={()=>{handleRightValue(null)}}>
          <Clear/>
        </IconButton>
      </section>
      <List className={classes.list}>
      {
        backCards.map((card,index)=>
          <Link to={'/card/'+card.id} className={classes.link} key={index}>
            <ListItem button onClick={()=>{handleRightValue(null)}}>
              <ListItemText primary={card.name}/>
            </ListItem>
          </Link>
        )
      }
      </List>
      {
        backCards.length === 0 &&
        <div className={classes.nullSection}>
          <Typography>No links to this card</Typography>
        </div>
      }
    </div>
  )
}
