import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Divider,
  AppBar,
  Toolbar,
  Button
} from '@material-ui/core';
import ProfileMenu from '../profile/ProfileMenu';
import GalleryTile from './GalleryTile';
import casette from '../images/casette.svg';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
  container:{
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]:{
      padding: theme.spacing(0,2)
    },
    [theme.breakpoints.up('md')]:{
      padding: theme.spacing(0,20)
    },
  },
  appBar:{
    borderBottom:'1px solid lightgrey'
  },
  logoImage:{
    height:'30px',
    width:'auto',
    marginRight:'20px'
  },
}));

export default function GalleryView(props){

  const classes = useStyles();
  const {workspaces,logOut} = props;

  return(
    <div>
      <AppBar
        position="fixed"
        elevation={0}
        color='white'
        className={classes.appBar}
      >
        <Toolbar>
          <img src={casette} alt={casette} className={classes.logoImage}/>
          <ProfileMenu logOut={logOut}/>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <Link to='/' style={{textDecoration:'none'}}>
          <Button
          variant='outlined'
          color='primary'
          >
          Back to My Remix
          </Button>
        </Link>
        <Typography variant='h4' style={{marginBottom:'20px',marginTop:'40px'}}>Gallery</Typography>
        <Divider/>
        <Grid container spacing={3} style={{marginTop:'20px'}}>
          {
            workspaces.map((item,index)=>
              <GalleryTile key={index} workspace={item}/>
            )
          }
        </Grid>
      </div>
    </div>
  )
}
