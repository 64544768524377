import React, {useCallback} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import{
  Clear,
  CallMade
} from '@material-ui/icons';
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';

const drawerWidth = 300;
const useStyles = makeStyles((theme)=>({
  rightDrawer:{
    width:drawerWidth,
    flexShrink:0,
    zIndex: theme.zIndex.appBar -1,
  },
  rightDrawerPaper:{
    borderTop:'1px solid lightgrey',
    width:drawerWidth,
    marginTop:theme.spacing(8),
    zIndex: theme.zIndex.appBar -1,
  },
  actions:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    marginLeft:theme.spacing(1)
  },
  title:{
    padding:theme.spacing(1)
  },
  content:{
    margin:theme.spacing(1),
    padding: theme.spacing(1),
    height:'30%',
    overflowY:'scroll',
    border:'1pt solid lightgrey',
    borderRadius:'5px'
  },
  backLinks:{
    marginLeft:theme.spacing(1),
    color:'grey'
  },
  nullSection:{
    marginLeft: theme.spacing(1)
  },
  link:{
    textDecoration:'none',
    color:theme.palette.primary.main
  },
  list:{
    overflowY:'auto',
    height:'25%',
  },
  group:{
    color:'grey'
  }
}));

export default function BlockRightDrawer(props){

  const classes = useStyles();

  const{
    card,
    setCard,
    links,
    cards
  }=props;

  const blocks = card ? card.blocks : [];
  const handleMouseEnter = ()=>{};

  const backLinks = card ? links.filter(link => link.target === card.id) : [];

  let backCards = [];
  backLinks.forEach(link=>{
    const backCard = cards.find(card=> card.id === link.source);
    if(backCard){
      backCards.push(backCard);
    }
  })
  const cardId = card ? card.id : '';

  return(
    <Drawer
    className={classes.rightDrawer}
    variant='persistent'
    anchor='right'
    classes={{
      paper: classes.rightDrawerPaper
    }}
    open={Boolean(card)}
    >
      <section className={classes.actions}>
        <Link to={'/card/'+cardId} className={classes.link}>
          <Button onClick={()=>{setCard(null)}} variant='outlined'>
          Open
          </Button>
        </Link>
        <IconButton onClick={()=>{setCard(null)}}>
          <Clear/>
        </IconButton>
      </section>
      <section className={classes.title}>
        <Typography variant='h5'>{card && card.name}</Typography>
        <Typography className={classes.group}>{card && card.group}</Typography>
      </section>
      <section className={classes.content}>
      {
        blocks.map((block,index)=>
          <Typography key={index}>{block.value}</Typography>
        )
      }
      </section>
      <section className={classes.backLinks}>
        <Typography>Back Links</Typography>
      </section>
      <List className={classes.list}>
      {
        backCards.map((card,index)=>
          <Link to={'/card/'+card.id} className={classes.link} key={index}>
            <ListItem button onClick={()=>{setCard(null)}}>
              <ListItemText primary={card.name}/>
            </ListItem>
          </Link>
        )
      }
      </List>
      {
        backCards.length === 0 &&
        <div className={classes.nullSection}>
          <Typography>No links to this card</Typography>
        </div>
      }
    </Drawer>
  )
}
