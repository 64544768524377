const timeConversion = 86400000;
const createdWeight = 0;
const updatedWeight = 0;
const viewedWeight = 0;
const linksWeight = .5;
const backLinksWeight = .5;
//durations will always be <1 and >0
//links and backlinks will always be integer >=0

// // Old Algorithm
// export const calculateNodeVal=(node,filteredLinks)=>{
//   const now = Date.now();
//   const createdDur = (node.created - now)/timeConversion;
//   const updatedDur = (node.lastUpdated - now)/timeConversion;
//   const viewedDur = (node.lastViewed - now)/timeConversion;
//
//   const links = filteredLinks.filter(link => link.source === node.id);
//   const backLinks = filteredLinks.filter(link => link.target === node.id);
//
//   const val = Number((createdDur*createdWeight) + (updatedDur*updatedWeight) + (viewedDur*viewedWeight) + (links.length*linksWeight) + (backLinks.length*backLinksWeight));
//
//   if(!val){
//     return 0;
//   }
//   return val.toFixed(4);
// }

export const calculateNodeVal=(node,filteredLinks)=>{
  const now = Date.now();
  const createdDur = Math.min((timeConversion/(now - node.created)),2);
  const updatedDur = Math.min((timeConversion/(now - node.lastUpdated)),5);
  const viewedDur = Math.min((timeConversion/(now - node.lastViewed)),2);
  //math.min sets the upper limit on the Y value

  const links = filteredLinks.filter(link => link.source === node.id);
  const backLinks = filteredLinks.filter(link => link.target === node.id);
  //should these also be asymptotic at 1?

  const val = Number((createdDur*createdWeight) + (updatedDur*updatedWeight) + (viewedDur*viewedWeight) + (links.length*linksWeight) + (backLinks.length*backLinksWeight));

  if(!val){
    return 0;
  }
  return val.toFixed(4);
}
