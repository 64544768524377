
export const stringifyBlocks=(blocks)=>{
  let output = '';
  blocks.forEach(function(block,index){
    output = output + block.value;
    if(index < blocks.length-1){
      output = output +'\n'
    }
  })
  return output;
}
