import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {
  Typography
} from '@material-ui/core';
import {Redirect} from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
  container:{
    width:'100%',
    height:'400px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
}));

export default function ErrorPage(props){
  const classes = useStyles();
  const {error,redirect} = props;

  if(redirect){
    return(
      <Redirect exact to={'/card/'+redirect}/>
    )
  }

  return(
    <div className={classes.container}>
      <Typography variant='h5'>{error}</Typography>
    </div>
  )
}
