import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import { makeStyles} from '@material-ui/core/styles';
import{
  Menu,
  MenuItem,
  ButtonBase,
  Typography,
  Divider
} from '@material-ui/core';
import {Check} from '@material-ui/icons';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
  link:{
    textDecoration:'none',
    color:'black'
  },
}))

export default function WorkspaceMenu(props){

    const classes = useStyles();
    const [anchor,setAnchor] = useState(null);
    const [workspaces, setWorkspaces] = useState([]);

    const {
      db,
      setWorkspaceOpen,
      profile,
      currentWorkspace
    } = props;

    useEffect(()=>{

      const fetchWorkspaces=(profile)=>{
        db.collection('Workspaces').where('owner','==',profile.uid).onSnapshot(function(snapshot){
          let workspaceList = [];
          snapshot.forEach(doc=>{
            let workspace = doc.data();
            workspace.id = doc.id;
            workspaceList.push(workspace);
          });
          setWorkspaces(workspaceList);
        });
      }

      if(profile){
        fetchWorkspaces(profile)
      }

    },[currentWorkspace,profile])

    return(
      <div>
      <ButtonBase onClick={profile ? (ev)=>{setAnchor(ev.currentTarget)} : null}>
        <Typography>{currentWorkspace && currentWorkspace.name}</Typography>
      </ButtonBase>
      <Menu
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={()=>{setAnchor(null)}}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {
          workspaces.map((item,index)=>
            <Link to={'/graph/'+item.id} key={index} className={classes.link}>
              <MenuItem onClick={()=>{setAnchor(null)}}>
              {item.name}
              </MenuItem>
            </Link>
          )
        }
        <Divider light/>
        <MenuItem onClick={()=>{
          setAnchor(null);
          setWorkspaceOpen(true);
        }}>
        + New Workspace
        </MenuItem>
      </Menu>
      </div>
    )
}
