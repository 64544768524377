var config = {
  apiKey: "AIzaSyCNCdzb421AgCPiobSS9T0gnQyayXaofQg",
  authDomain: "editor-ff68a.firebaseapp.com",
  databaseURL: "https://editor-ff68a.firebaseio.com",
  projectId: "editor-ff68a",
  storageBucket: "editor-ff68a.appspot.com",
  messagingSenderId: "449840740677",
  appId: "1:449840740677:web:24423d7ea220c3e1ada62c",
  measurementId: "G-MPD6P627X3"
};
export default config;
