import React, {useState,useEffect,useRef} from 'react';
import {
  Typography,
  InputBase,
  ButtonBase,
  Popper,
  Paper
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
const interval = process.env.REACT_APP_INTERVAL;

const useStyles = makeStyles((theme)=>({
  container:{
    width:'100%'
  },
  selected:{
    backgroundColor:'#ACCEF7'
  },
  default:{
    width:'100%'
  },
  headerOne:{
    width:'100%',
    fontSize:'30px'
  },
  headerTwo:{
    width:'100%',
    fontSize:'22px'
  },
  bold:{
    width:'100%',
    fontWeight:'bold'
  },
  implied:{
    textDecorationLine:'underline',
    textDecorationColor:theme.palette.secondary.main,
    width:'100%'
  },
  bullet:{
    width:'100%',
    display:'list-item',
    marginLeft:'20px'
  },
  italic:{
    width:'100%',
    fontStyle:'italic'
  },
  popover:{
    padding:'10px'
  },
  zeroIndent:{

  },
  oneIndent:{
    marginLeft:'40px'
  },
  twoIndent:{
    marginLeft:'80px'
  },
  threeIndent:{
    marginLeft:'120px'
  }
}))

export default function EditableBlock(
  {
    block,
    index,
    updateText,
    blockCount,
    addBlock,
    deleteBlock,
    setMenu,
    focus,
    setFocus,
    setSearchOpen,
    updateBlockType,
    cards,
    blocks,
    addInternal,
    cardId,
    profile,
    updateBlockIndent,
    selectBlock,
    selectedBlocks,
    clearSelectedBlocks,
    setMouseDown,
    mouseDown,
    copyBlocks,
    pasteBlocks,
    cutBlocks,
    copiedBlocks,
    deleteBlocks,
    clearCopiedBlocks
  }
){
  const classes = useStyles();
  let className = 'default';
  switch(block.type){
    case 'h1':
      className = 'headerOne';
      break;
    case 'h2':
      className = 'headerTwo';
      break;
    case 'bold':
      className = 'bold';
      break;
    case 'italic':
      className = 'italic';
      break;
    case 'bullet':
      className = 'bullet';
      break;
    default:
      className = 'default';
  }

  let indent = 'zeroIndent';
  switch(block.indent){
    case 1:
      indent = 'oneIndent'
      break;
    case 2:
      indent = 'twoIndent';
      break;
    case 3:
      indent = 'threeIndent';
      break;
    default:
      indent = 'zeroIndent';
  }

  const [value,setValue] = useState(block.value ? block.value : '');
  const [anchor, setAnchor] = useState(null);
  const [implied, setImplied] = useState(null);

  const inputRef = useRef(null);

  useEffect(()=>{

    const checkImplied=(content)=>{
      for(const card of cards){
        if(content.includes(card.name) && ! (cardId === card.id)){
          let match = false;
          for(const item of blocks){
            if(item.type === 'internal' && item.target === card.id){
              match = true;
              break;
            }
          }
          if(!match){
            setImplied(card);
            break;
          }
        }
      }
    }

    setValue(block.value);
    if(focus === index){
      inputRef.current.focus();
    }
    if(block.type === 'text'){
      checkImplied(block.value);
    }

    return function cleanUp(){
      setValue('');
      setImplied(null);
    }
  },[block.id,focus,cards.length,blocks.length])

  const handleChange=(ev)=>{
    const newValue = ev.target.value;
    setValue(newValue);
    clearTimeout(window.textBlockTimeOut);
    window.textBlockTimeOut = setTimeout(function(){
      updateText(newValue,index);
    },interval);
  }

  const handleKeyDown=(ev)=>{
    if(ev.key === 'Enter' || ev.keyCode === '13'){
      ev.preventDefault();
      clearTimeout(window.textBlockTimeOut);
      updateText(value,index);
      if(value === ''){
        addBlock(index+1);
      }else if(ev.target.selectionStart === 0){
        addBlock(index);
      }else{
        addBlock(index+1);
      }
    }else if(ev.key === 'Backspace' || ev.keyCode === '8'){
      if(selectedBlocks.length){
        ev.preventDefault();
        deleteBlocks();
      }else if(blockCount >1 && !value){
        ev.preventDefault();
        clearTimeout(window.textBlockTimeOut);
        updateText(value,index);
        deleteBlock(index);
      }
    }else if((ev.key === '/' || ev.keyCode === '9') && !value){
      ev.preventDefault();
      setMenu(ev.currentTarget,index);
    }else if(ev.key === 'ArrowUp' || ev.keyCode === '38'){
      ev.preventDefault();
      setFocus(index -1);
    }else if (ev.key === 'ArrowDown' || ev.keyCode === '40'){
      ev.preventDefault();
      setFocus(index +1);
    }else if(ev.ctrlKey && (ev.key === 's' || ev.keyCode === '83')){
      ev.preventDefault();
      setSearchOpen(true);
    }else if(ev.metaKey && (ev.key === 'b' || ev.keyCode === '66')){
      ev.preventDefault();
      let type = 'bold';
      if(block.type === 'bold'){
        type = 'text';
      }
      updateBlockType(type,index);
    }else if(ev.metaKey && (ev.key === 'i' || ev.keyCode === '73')){
      ev.preventDefault();
      let type = 'italic';
      if(block.type === 'italic'){
        type = 'text';
      }
      updateBlockType(type,index);
    }else if(ev.keyCode ==='32' || ev.key=== ' '){
      if(value ==='#'){
        ev.preventDefault();
        updateBlockType('h1',index);
        setValue('');
      }else if(value === '##'){
        ev.preventDefault();
        updateBlockType('h2',index);
        setValue('');
      }else if(value === '-'){
        ev.preventDefault();
        updateBlockType('bullet',index);
        setValue('');
      }
    }else if(value ==='' && (ev.keyCode === '9' || ev.key=== 'Tab')){
      ev.preventDefault();
      const indent = block.indent ? Number(block.indent) + 1 : 1;
      updateBlockIndent(indent,index);
    }
    else if(ev.shiftKey && (ev.keyCode === '9' || ev.key=== 'Tab')){
      ev.preventDefault();
      const indent = block.indent ? Number(block.indent) -1 : 0;
      updateBlockIndent(indent,index);
    }else if(ev.metaKey &&(ev.keyCode === '67' || ev.key === 'c')){
      if(selectedBlocks.length){
        ev.preventDefault();
        const result = copyBlocks();
        navigator.clipboard.writeText(result);
      }else{
        clearCopiedBlocks();
      }
    }else if(ev.metaKey &&(ev.keyCode === '88' || ev.key === 'x')){
      if(selectedBlocks.length){
        ev.preventDefault();
        const result = cutBlocks();
        navigator.clipboard.writeText(result);
      }else{
        clearCopiedBlocks();
      }
    }else if(ev.metaKey &&(ev.keyCode === '86' || ev.key === 'v')){
      if(copiedBlocks.length){
        ev.preventDefault();
        pasteBlocks();
      }
    }
  }

  // const handleMouseUp=(ev)=>{
    // const selection = window.getSelection();
    // if(selection.type === 'Range'){
    //   console.log(selection.toString())
    //   setAnchor({
    //     title:selection.toString(),
    //     x:ev.clientX,
    //     y:ev.clientY
    //   })
    // }
  // }

  const handleFocus =()=>{
    if(focus !== index){
      setFocus(index);
    }
    if(selectedBlocks.length){
      clearSelectedBlocks();
    }
  }

  const handleMouseEnter=(ev)=>{
    if(implied && profile){
      setAnchor(ev.currentTarget)
    }
    const selection = window.getSelection();
    if(mouseDown && selection.type === 'Range'){
      selectBlock(block);
    }
  }

  const handleMouseLeave=(ev)=>{
    if(block.id){
      clearTimeout(window[block.id]);
      window[block.id] = setTimeout(function(){
        setAnchor(null);
      },interval);
    }
  }

  const handleImpliedEnter=()=>{
    clearTimeout(window[block.id]);
  }

  const handleAdd=()=>{
    if(implied){
      addInternal(implied.id,implied.name,index+1)
    }
    setAnchor(null);
    setImplied(null);
  }

  const isFocused = focus === index;

  const selected = selectedBlocks.find(item => item.id === block.id);

  return(
    <div className={selected ? classes.selected : classes.container}>
      <InputBase
      inputRef={inputRef}
      className={implied ? `${classes['implied']} ${classes[indent]}` : `${classes[className]} ${classes[indent]}`}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={isFocused ? 'Press / for options' : ''}
      multiline
      onFocus={handleFocus}
      onMouseDown={()=>{setMouseDown(true)}}
      onMouseUp={()=>{setMouseDown(false)}}
      onMouseEnter={(ev)=>{handleMouseEnter(ev)}}
      onMouseLeave={handleMouseLeave}
      disabled={!profile}
      />
      <Popper
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={()=>{setAnchor(null)}}
      placement='top-start'
      onMouseEnter={handleImpliedEnter}
      onMouseLeave={handleMouseLeave}
      >
      <Paper className={classes.popover}>
        <ButtonBase
        onClick={handleAdd}
        >
          <Typography>{implied && implied.name}</Typography>
        </ButtonBase>
      </Paper>
      </Popper>
    </div>
  )
}

      // className={implied ? classes['implied'] : classes[className]}
