import React, {useState, useEffect} from 'react';
import {
  useParams
} from "react-router-dom";
import {useSelector,useDispatch} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import MainFrame from './MainFrame';
import {
  parseChildren
} from '../graph/graph';
import{
  calculateNodeVal
} from '../intelligence/intelligence';
import {Redirect} from 'react-router-dom';
import ErrorPage from '../notify/ErrorPage';
import {
  setWorkspace,
} from '../redux/userReducer.js';
import {
  setCards,
  setLinks,
  setData
} from '../redux/dataReducer.js';

const defaultContent = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export default function MainContainer(props){

  const [workspaces,setWorkspaces] = useState([]);
  const [error, setError] = useState(null);

  const{
    logOut,
    db,
  } = props;

  let {
    type,
    id
  } = useParams();

  const profile = useSelector(state => state.user.profile);
  const authLoaded = useSelector(state => state.user.authLoaded);
  const currentWorkspace = useSelector(state => state.user.workspace);
  const cards = useSelector(state => state.data.cards);
  const links = useSelector(state => state.data.links);

  const dispatch = useDispatch();

  let unsubWorkspace = null;

  const generateData=(cards,links,groups)=>{
    const filteredNodes = cards.filter((node)=>{
      const category = groups.find(item => item.title == node.group);
      if(category){
        return category.enabled;
      }
      return false;
    })

    const filteredLinks = links.filter(link=>
      (filteredNodes.find(node=>node.id === link.source)) && (filteredNodes.find(node=>node.id === link.target))
    )
    const data={nodes:JSON.parse(JSON.stringify(filteredNodes)),links:JSON.parse(JSON.stringify(filteredLinks))}
    dispatch(setData(data));
  }

  const parseCards =(snapShot,groups)=>{
    let nodes =[];
    let links =[];
    //loop through nodes
    snapShot.forEach(doc=>{
      let node = doc.data();
      node.id = doc.id;
      let blocks = node.blocks ? node.blocks : [];
      blocks.forEach(block=>{
        if(block.type === 'internal'){
          links.push(block);
        }
      });
      nodes.push(node);
    });
    //make sure links point to existant nodes
    const filteredLinks = links.filter(link=>
      (nodes.find(node=>node.id === link.source)) && (nodes.find(node=>node.id === link.target))
    )
    //calc backlinks and vals
    nodes.forEach(node=>{
      node.val = calculateNodeVal(node,filteredLinks);
    })
    //set data
    dispatch(setLinks(filteredLinks));
    dispatch(setCards(nodes));
    generateData(nodes,filteredLinks,groups);
  };

  const fetchCards=async()=>{
    const result = await db.collection('Cards')
    .where('workSpace','==',currentWorkspace.id)
    .get();
    parseCards(result,currentWorkspace.categories);
  }

  const initFetch=async(workspaceId,groups)=>{
    console.log('init')
     const result = await db.collection('Cards')
    .where('workSpace','==',workspaceId)
    .get();
    parseCards(result,groups);
  }

  const checkPermissions=(workspace)=>{
    if(workspace.publicEdit){
      return true;
    }
    if(profile){
      if(workspace.owner === profile.uid){
        return true;
      }
      if(workspace.collaborators && workspace.collaborators.includes(profile.uid)){
        return true;
      }
    }
    return false;
  }

  const setCurrent=(workspace)=>{
    if(profile && workspace.owner === profile.uid && (workspace.id !== profile.currentWorkspace)){
      db.collection('Profiles').doc(profile.uid).update({
        currentWorkspace: workspace.id
      });
    }
  }

  const listenWorkspace=async(workspaceId)=>{

    unsubWorkspace = db.collection('Workspaces').doc(workspaceId).onSnapshot(function(snapshot){
      if(snapshot.exists){
        let workspace = snapshot.data();
        workspace.id = snapshot.id;
        if(checkPermissions(workspace)){
          dispatch(setWorkspace(workspace));
          setCurrent(workspace);
          initFetch(workspace.id,workspace.categories);
          setError(null);
        }else if(authLoaded){
          setError('You do not have permission to access this Workspace.')
        }
      }
    })
  }

  useEffect(()=>{

    document.addEventListener('contextmenu',function(e){
      e.preventDefault();
    })

    const fetchCard=async(cardId)=>{
      const result = await db.collection('Cards').doc(cardId).get();
      if(result.exists){
        const workspace = result.data().workSpace;
        listenWorkspace(workspace);
      }
    }

    // if(type ==='graph'){
    //   listenWorkspace(id);
    // }else if(!currentWorkspace && type==='card'){
    //   fetchCard(id);
    // }

    if(!currentWorkspace && type === 'card'){
      fetchCard(id);
    }else if(currentWorkspace && currentWorkspace.id !== id){
      listenWorkspace(id);
    }else if(!currentWorkspace && type === 'graph'){
      listenWorkspace(id);
    }

    return function cleanUp(){
      if(unsubWorkspace){
        unsubWorkspace();
      }
    }
  },[profile,id,authLoaded]);

  const addCard=async(name,group,root,parent)=>{
    const targetId = uuidv4();
    const timeStamp = Date.now();
    db.collection('Cards').doc(targetId).set({
      name: name,
      owner: profile.uid,
      root: root,
      created: timeStamp,
      lastViewed: timeStamp,
      lastUpdated: timeStamp,
      group: group,
      workSpace: currentWorkspace.id,
      parent: parent,
      blocks:[{id:uuidv4(),type:'text',value:''}]
    })
    fetchCards()
    return targetId;
  }

  const deleteCard=async(uid)=>{
    await db.collection('Cards').doc(uid).delete();
    fetchCards();
  }

  const handleFetchCards=()=>{
    fetchCards();
  }

  const updateCategories=(newCategories)=>{
    let newWorkspace = JSON.parse(JSON.stringify(currentWorkspace));
    newWorkspace.categories = newCategories;
    dispatch(setWorkspace(newWorkspace));
    generateData(cards,links,newCategories);
    if(profile && profile.uid === newWorkspace.owner){
      db.collection('Workspaces').doc(currentWorkspace.id).update({
        'categories':newCategories
      })
    }
  }

  if(error){
    return(
      <ErrorPage error={error}/>
    )
  }

  //no render without workspace?
  if(!currentWorkspace){
    return(
      <div/>
    )
  }

  return(
    <MainFrame
    logOut={logOut}
    db={db}
    addCard={addCard}
    cardId={type === 'card' ? id : null}
    fetchCards={handleFetchCards}
    deleteCard={deleteCard}
    fetchWorkspace={listenWorkspace}
    updateCategories={updateCategories}
    />
  )
}
