import React, {useState} from 'react';
import {
  Typography,
  IconButton,
  InputBase
} from '@material-ui/core';
import {
  Delete,
  Add
} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
  container:{
    display:'flex',
    alignItems:'center',
    height:'30px'
  },
  selected:{
    display:'flex',
    alignItems:'center',
    height:'30px',
    backgroundColor:'#ACCEF7'
  },
  link:{
    textDecoration:'none',
    color: theme.palette.primary.main
  },
  show:{

  },
  hide:{
    display:'none'
  },
  url:{
    textDecoration:'none',
    color: theme.palette.secondary.main
  },
  precursor:{
    width:'2px'
  }
}))

export default function LinkBlock({block,index,deleteBlock,addBlock,blockCount,
  updateBlock,profile,mouseDown,selectBlock,setMouseDown,selectedBlocks}){

  const classes= useStyles();

  const [hover,setHover] = useState(false);

  const handleUpdate=()=>{
    const newBlock = block;
    block.type = 'text';
    block.value = '';
    updateBlock(newBlock,index,true);
  }

  const handleKeyDown=(ev)=>{
    if(ev.key === 'Enter' || ev.keyCode === '13'){
      ev.preventDefault();
      if(ev.target.selectionStart === 0){
        addBlock(index);
      }
    }
  }

  const handleMouseEnter=()=>{
    if(profile){
      setHover(true);
    }
    const selection = window.getSelection();
    if(mouseDown && selection.type === 'Range'){
      selectBlock(block);
    }
  }

  const selected = selectedBlocks.find(item => item.id === block.id);

  return(
    <div className={selected ? classes.selected : classes.container} onMouseEnter={handleMouseEnter} onMouseLeave={()=>{setHover(false)}} onClick={()=>{setHover(true)}} onMouseUp={()=>{setMouseDown(false)}}>
      <InputBase value='' onKeyDown={handleKeyDown} className={classes.precursor}/>
      {
        (block.type === 'internal' || block.type === 'global')?
        <Link to={'/card/'+block.target} className={classes.link}>
          <Typography>{block.value}</Typography>
        </Link>
        :
        <a href={block.url} className={classes.url} rel="noopener noreferrer" target="_blank">
          <Typography>{block.value}</Typography>
        </a>
      }
      <IconButton
      size='small'
      className={hover ? classes.show : classes.hide}
      onClick={blockCount === 1 ? handleUpdate : ()=>{deleteBlock(index)}}
      >
        <Delete/>
      </IconButton>
      <IconButton
      size='small'
      className={hover ? classes.show : classes.hide}
      onClick={()=>{addBlock(index+1)}}
      >
        <Add/>
      </IconButton>
    </div>
  )
}
