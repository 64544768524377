import React, {useState, useEffect} from 'react';
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Hidden,
  SwipeableDrawer,
  Divider
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import {
  ChevronLeft,
  Menu,
  Add,
  BubbleChart,
  Search,
  Clear,
  Settings
} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import casette from '../images/casette.svg';
import ProfileMenu from '../profile/ProfileMenu';
import ErrorPage from '../notify/ErrorPage';
import EditGraphView from '../graph/EditGraphView';
import EditCardContainer from '../editor/EditCardContainer';
import SideCardTile from '../editor/SideCardTile';
import ClusterDialog from './ClusterDialog';
import SearchDialog from './SearchDialog';
import WorkspaceMenu from './WorkspaceMenu';
import NewWorkspaceDialog from './NewWorkspaceDialog';
import Edit2DGraphView from '../graph/Edit2DGraphView';
import GraphControls from '../graph/GraphControls';
import {useSelector, useDispatch} from 'react-redux';
import SettingsDialog from './SettingsDialog';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:'white',
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor:'white',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color:'black'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  graph:{
    marginLeft: -drawerWidth,
  },
  graphShift:{

  },
  // cardContainer:{
  //   marginLeft: theme.spacing(0)
  // },
  // cardContainerShift:{
  //   marginLeft: theme.spacing(5)
  // },
  logoButton:{
    display:'flex',
    alignItems:'center',
    textDecoration:'none',
    color:'black',
    marginLeft:'10px'
  },
  logoImage:{
    height:'30px',
    width:'auto',
    marginRight:'10px'
  },
  addCard:{
    position:'fixed',
    bottom:'20px',
    width:drawerWidth
  },
  agendaList:{
    overflowY:'scroll'
  },
  sideBar:{
    overflowY:'scroll',
    // height:`calc(100% - 470px)`,
    height:'50%'
  },
  recent:{
    overflowY:'scroll',
    height:'100%'
  },
  quickFind:{
    height:`calc(100% - 300px)`,
  },
  graphButton:{
    marginLeft:'40px',
  },
  link:{
    textDecoration:'none',
    color:'black'
  },
  cardsLabel:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
    marginLeft:theme.spacing(4),
    color:'gray',
    fontSize:'14px'
  },
  drawerContainer:{
    height:'100%'
  },
  actions:{
    marginBottom: theme.spacing(1)
  }
}));

export default function EditGraphFrame(props){
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [clusterAnchor, setClusterAnchor] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [rightValue,setRightValue] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [workspaceOpen, setWorkspaceOpen] = useState(false);
  const [graphSearchOpen, setGraphSearchOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [graphType, setGraphType] = useState('3d');
  const [settingsOpen, setSettingsOpen] = useState(false);

  const currentWorkspace = useSelector(state => state.user.workspace);
  const profile = useSelector(state => state.user.profile);
  const cards = useSelector(state => state.data.cards);

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRightValue=(value)=>{
    if(!value){
      setRightValue(null);
    }else{
      setRightValue(value);
    }
  }

  const {
    cardId,
    logOut,
    error,
    db,
    addCard,
    deleteCard,
    fetchCards,
    fetchWorkspace,
    updateCategories,
  } = props;

  const handleAddCard=async(name)=>{
    const cardId = await addCard(name,'None',true,null);
    if(cardId){
      setRedirect(cardId);
    }
  }

  const handleRedirect=(cardId)=>{
    setRedirect(cardId);
  }

  const handleGraphType=(type)=>{
    setGraphType(type);
  }

  useEffect(()=>{
    if(cardId !== 0){
      setRedirect(null);
    }

    return function cleanUp(){
      setMobileOpen(false);
    }
  },[cardId,cards,currentWorkspace]);

  const roots = cards.filter(node => node.root);
  roots.sort(function(a,b){
    return b.val - a.val
  })

  const recent = cards.sort(function(a,b){
    return b.lastViewed - a.lastViewed;
  }).slice(0,20);

  const drawer = (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <div style={{display:'flex',alignItems:'center'}}>
          <Link to={'/gallery'} className={classes.logoButton} >
            <img src={casette} alt={casette} className={classes.logoImage}/>
          </Link>
          <WorkspaceMenu db={db} setWorkspaceOpen={setWorkspaceOpen} profile={profile} currentWorkspace={currentWorkspace}/>
        </div>
        <Hidden smUp>
          <IconButton onClick={()=>{setMobileOpen(false)}}>
            <ChevronLeft/>
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft/>
          </IconButton>
        </Hidden>
      </div>
      <section className={classes.actions}>
        <ListItem dense button onClick={()=>{setSearchOpen(true)}}>
          <ListItemIcon>
            <Search style={{color:'black'}}/>
          </ListItemIcon>
          <ListItemText primary='Search'/>
        </ListItem>
        <Link to={'/graph/'+currentWorkspace.id} className={classes.link}>
          <ListItem dense button onClick={()=>{setMobileOpen(false)}}>
            <ListItemIcon>
              <BubbleChart style={{color:'black'}}/>
            </ListItemIcon>
            <ListItemText primary='Graph'/>
          </ListItem>
        </Link>
        {
          (profile && currentWorkspace &&profile.uid === currentWorkspace.owner) &&
          <ListItem dense button onClick={()=>{setSettingsOpen(true)}}>
            <ListItemIcon>
              <Settings style={{color:'black'}}/>
            </ListItemIcon>
            <ListItemText primary='Settings'/>
          </ListItem>
        }
      </section>
      <Divider/>
      <section className={classes.quickFind}>
      <Typography className={classes.cardsLabel}>Recent</Typography>
      {
        recent.length >1 &&
        <List className={classes.recent}>
          {
            recent.map((card,index)=>
              <SideCardTile card={card} cards={cards} key={index} deleteCard={deleteCard} profile={profile}/>
            )
          }
        </List>
      }
      </section>
      {
        profile &&
        <ListItem dense button className={classes.addCard} key='add' onClick={(ev)=>{setClusterAnchor(ev.currentTarget)}}>
          <ListItemIcon>
            <Add/>
          </ListItemIcon>
          <ListItemText primary={'Add Card'}/>
        </ListItem>
      }
      </div>
    );

  if(error){
    return(
      <ErrorPage error={error} redirect={redirect}/>
    )
  }

  // if(profile){
    return(
      <div className={classes.root}>
        <Hidden smUp>
          <AppBar
            position="fixed"
            className={classes.appBar}
            elevation={0}
            color='transparent'
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={()=>{setMobileOpen(true)}}
                edge="start"
                className={classes.menuButton}
              >
                <Menu/>
              </IconButton>
              <ProfileMenu logOut={logOut}/>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Hidden xsDown>
          <AppBar
            position="fixed"
            className={open ? classes.appBarShift : classes.appBar}
            elevation={0}
            color='transparent'
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={open ? classes.hide : classes.menuButton}
              >
                <Menu/>
              </IconButton>
              <ProfileMenu logOut={logOut}/>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Hidden smUp>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={()=>{setMobileOpen(false)}}
            onOpen={()=>{setMobileOpen(true)}}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={open ? classes.contentShift : classes.content}>
          <div className={classes.drawerHeader} />
          {
            !cardId ?
            <div className={open ? classes.graphShift : classes.graph}>
            <GraphControls
            open={open}
            setSearchOpen={setGraphSearchOpen}
            graphType={profile ? profile.graphType : '3d'}
            handleGraphType={handleGraphType}
            setFilterOpen={setFilterOpen}
            profile={profile}
            graphType={graphType}
            />
              {
                graphType === '2d' ?
                <Edit2DGraphView
                addCard={addCard}
                cardId={cardId}
                handleRedirect={handleRedirect}
                redirect={redirect}
                db={db}
                searchOpen={graphSearchOpen}
                setSearchOpen={setGraphSearchOpen}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
                updateCategories={updateCategories}
                fetchCards={fetchCards}
                deleteCard={deleteCard}
                />
                :
                <EditGraphView
                addCard={addCard}
                cardId={cardId}
                handleRedirect={handleRedirect}
                redirect={redirect}
                db={db}
                searchOpen={graphSearchOpen}
                setSearchOpen={setGraphSearchOpen}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
                updateCategories={updateCategories}
                fetchCards={fetchCards}
                deleteCard={deleteCard}
                />
              }
            </div>
            :
            <div className={open ? classes.cardContainerShift : classes.cardContainer }>
            <EditCardContainer
            cardId={cardId}
            db={db}
            addCard={addCard}
            redirect={redirect}
            fetchCards={fetchCards}
            handleRightValue={handleRightValue}
            rightValue={rightValue}
            deleteCard={deleteCard}
            setSearchOpen={setSearchOpen}
            fetchWorkspace={fetchWorkspace}
            />
            </div>
          }
        </main>
        <ClusterDialog anchor={clusterAnchor} setAnchor={setClusterAnchor} addCard={handleAddCard}/>
        <SearchDialog open={searchOpen} setOpen={setSearchOpen} selectCard={handleRedirect} cards={cards}/>
        <NewWorkspaceDialog open={workspaceOpen} setOpen={setWorkspaceOpen} db={db} profile={profile}/>
        <SettingsDialog open={settingsOpen} setOpen={setSettingsOpen} db={db}/>
      </div>
    )
  // }

  // return(
  //   <div className={classes.root}>
  //
  //   </div>
  // )

}
