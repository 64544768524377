import React, {useState} from 'react';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
  container:{
    padding:theme.spacing(4),
  },
  input:{
    width:'300px'
  }
}))

export default function NewCardPopover(props){
  const classes = useStyles();

  const{
    anchor,
    setAnchor,
    addCard,
  } = props;

  const [value, setValue] = useState('');

  const handleClose=()=>{
    setAnchor(null);
  }

  const handleSubmit=(option)=>{
    addCard(value);
    setValue('');
    handleClose();
  }

  const handleKeyDown=(ev)=>{
    if(ev.key === 'Enter' || ev.keyCode === '13'){
      ev.preventDefault();
      handleSubmit();
    }
  }

  return(
    <Dialog
    open={Boolean(anchor)}
    onClose={handleClose}
    classes={{
      paper:classes.container
    }}
    >
      <DialogTitle>New Card</DialogTitle>
      <DialogContent>
        <TextField
        placeholder='Card Name'
        autoFocus
        value={value}
        onChange={(ev)=>{setValue(ev.target.value)}}
        onKeyDown={handleKeyDown}
        />
      </DialogContent>
    </Dialog>
  )
}
