import React, {useState,useEffect,useRef} from 'react';
import {
  InputBase,
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
  container:{
    outline:'none'
  }
}))

export default function TextLeaf(props){

  const classes = useStyles();

  const{
    initValue
  } = props;

  const value = useRef(initValue);

  const handleInput=(ev)=>{
    ev.preventDefault();
    value.current = (ev.currentTarget.textContent)
  }

  return(
    <span className={classes.container} contentEditable onInput={(ev)=>{handleInput(ev)}}>
      {value.current}
    </span>
  )
}
