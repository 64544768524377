import React, {useState, useEffect} from 'react';
import GalleryView from './GalleryView';

export default function GalleryContainer(props){

    const [workspaces,setWorkspaces] = useState([]);
    const {db,logOut} = props;

    useEffect(()=>{

      const fetchWorkspaces=async()=>{
        const result = await db.collection('Workspaces').where('publicEdit','==',true).get();
        if(result){
          let workspaceList = [];
          result.forEach(doc=>{
            let workspace = doc.data();
            workspace.id = doc.id;
            workspaceList.push(workspace);
          });
          setWorkspaces(workspaceList);
        }
      }

      fetchWorkspaces();
    },[db])

    return (
      <GalleryView
      workspaces={workspaces}
      logOut={logOut}
      />
    )

}
