import React, {useState, useEffect} from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Checkbox
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import {
  setWorkspace,
} from '../redux/userReducer.js';
import {useSelector, useDispatch} from 'react-redux';

const interval = process.env.REACT_APP_INTERVAL;

const useStyles = makeStyles((theme)=>({
  container:{
    padding:theme.spacing(1),
  },
  public:{
    display:'flex',
    alignItems:'center'
  },
  sharing:{
    marginTop:'40px'
  }
}))

export default function SettingsDialog(props){
  const classes = useStyles();

  const{
    open,
    setOpen,
    db
  } = props;

  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(state => state.user.workspace);

  useEffect(()=>{
    setValue(currentWorkspace.name);
  },[currentWorkspace])

  const handleClose=()=>{
    setOpen(false);
  }

  const updateTitle = async(value)=>{
    const newWorkspace = JSON.parse(JSON.stringify(currentWorkspace));
    newWorkspace.name = value;
    dispatch(setWorkspace(newWorkspace));
    await db.collection('Workspaces').doc(currentWorkspace.id).update({
      name:value,
    });
  }

  const handleTitle = async(ev)=>{
      const title = ev.target.value;
      setValue(title);
      clearTimeout(window.workspaceTimeOut);
      window.workspaceTimeOut = setTimeout(function(){
        updateTitle(title);
      },interval);
  }

  const handlePublic=(value)=>{
    const newWorkspace = JSON.parse(JSON.stringify(currentWorkspace));
    newWorkspace.publicEdit = value;
    dispatch(setWorkspace(newWorkspace));
    db.collection('Workspaces').doc(currentWorkspace.id).update({
      publicEdit:value
    })
  }

  return(
    <Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paper:classes.container
    }}
    >
    <DialogTitle>Workspace Settings</DialogTitle>
    <DialogContent>
      <Typography>Title</Typography>
      <TextField
      placeholder='Workspace Name'
      autoFocus
      value={value}
      onChange={handleTitle}
      />
      <section className={classes.sharing}>
        <Typography>Sharing</Typography>
        <div className={classes.public}>
          <Checkbox
          edge='start'
          checked={currentWorkspace.publicEdit ? currentWorkspace.publicEdit : false}
          onChange={(ev)=>{handlePublic(ev.target.checked)}}
          />
          <Typography>Public (anyone can edit)</Typography>
        </div>
      </section>
    </DialogContent>
    </Dialog>
  )
}
