import React, {useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  IconButton
} from '@material-ui/core';
import{
  Clear,
  Done
} from '@material-ui/icons';

import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
  container:{
    display:'flex',
    flexDirection:'column'
  },
  input:{
    width:'200px'
  },
  actions:{
    display:'flex'
  }
}))

export default function ExternalLInkPopover(props){
  const classes = useStyles();

  const [url,setUrl] = useState('');
  const [label,setLabel] = useState('');

  const{
    anchor,
    setAnchor,
    addLink
  } = props;

  const handleSubmit=()=>{
    addLink(url,label);
    handleClose();
  }

  const handleClose=()=>{
    setUrl('');
    setLabel('');
    setAnchor(null);
  }

  return(
    <Dialog
    open={Boolean(anchor)}
    onClose={handleClose}
    >
      <DialogContent className={classes.container}>
        <TextField className={classes.input} value={url} onChange={(ev)=>{setUrl(ev.target.value)}} placeholder='url' autoFocus/>
        <TextField className={classes.input} value={label} onChange={(ev)=>{setLabel(ev.target.value)}} placeholder='link text'/>
        <DialogActions>
          <IconButton onClick={handleClose}><Clear/></IconButton>
          <IconButton onClick={handleSubmit}><Done/></IconButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
