const style ={
  container:{
    width:'100%',
    height:'500px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
  },
  form:{
    width:'400px',
    height:'200px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  }
}
export default style;
