import React, {useState,useEffect,useMemo,useCallback,useRef} from 'react';
import { makeStyles} from '@material-ui/core/styles';
//components
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Fab,
  Drawer,
  Hidden,
} from '@material-ui/core';
//icons
import{
  FlashOn,
  Link
} from '@material-ui/icons';
import {Redirect} from 'react-router-dom';
//blocks
import TextBlock from './TextBlock';
import LinkBlock from './LinkBlock';
//selects
import CardMenu from './CardMenu';
import GroupSelector from './GroupSelector';
import {v4 as uuidv4} from 'uuid';
//menus
import InternalLinkPopover from './InternalLinkPopover';
import ExternalLinkPopover from './ExternalLinkPopover';
import NewCardPopover from './NewCardPopover';
import BackLinkMenu from './BackLinkMenu';
import SuggestionMenu from './SuggestionMenu';
import BlockOptions from './BlockOptions';
import MobileBlockOptions from './MobileBlockOptions';
import NewGroupDialog from './NewGroupDialog';
import EditableBlock from './EditableBlock';
import {stringifyBlocks} from './block.js';
import {setCopiedBlocks} from '../../redux/dataReducer';
import {useSelector,useDispatch} from 'react-redux';
import InlineBlock from './InlineBlock';

const interval = process.env.REACT_APP_INTERVAL;
const drawerWidth = 260;

const useStyles = makeStyles((theme)=>({
  container:{
    paddingBottom:theme.spacing(10),
    display:'flex',
    flexDirection:'column',
  },
  header:{
    display:'flex',
    width:'90%',
    justifyContent:'space-between',
    alignItems:'center',
    [theme.breakpoints.down('xs')]:{
      margin: theme.spacing(0,5,2,3)
    },
    [theme.breakpoints.up('sm')]:{
      margin: theme.spacing(0,10,2,15)
    }
  },
  group:{
    [theme.breakpoints.down('xs')]:{
      margin: theme.spacing(0,5,2,3)
    },
    [theme.breakpoints.up('sm')]:{
      margin: theme.spacing(0,10,2,15)
    }
  },
  name:{
    fontSize:40,
    fontWeight:'bold',
    width:'90%'
  },
  blocks:{
    display:'flex',
    flexDirection:'column',
    [theme.breakpoints.down('xs')]:{
      margin: theme.spacing(0,5,2,3)
    },
    [theme.breakpoints.up('sm')]:{
      margin: theme.spacing(0,10,2,15)
    }
  },
  actions:{
    position:'fixed',
    bottom:theme.spacing(3),
    right:theme.spacing(3),
    zIndex: theme.zIndex.appBar+1,
    display:'flex',
    flexDirection:'column'
  },
  rightDrawer:{
    width:drawerWidth,
    flexShrink:0,
    zIndex: theme.zIndex.appBar -1,
  },
  rightDrawerPaper:{
    width:drawerWidth,
    paddingTop:theme.spacing(8)
  },
  mobileBar:{
    position:'fixed',
    bottom:0,
    width:'100%',
    borderTop:'1px solid lightgrey',
    backgroundColor:'white'
  }
}))

export default function BlockEditView(props){

  const classes = useStyles();

  const{
    card,
    updateBlocks,
    deleteCard,
    updateGroup,
    groups,
    updateName,
    cards,
    addCard,
    rightValue,
    handleRightValue,
    backLinks,
    redirect,
    setSearchOpen,
    profile,
    db,
    addGroup
  } = props;

  const [name,setName] = useState(card.name);
  const [blocks,setBlocks] = useState(card.blocks ? card.blocks : []);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedIndex,setSelectedIndex] = useState(null);
  const [internalAnchor,setInternalAnchor] = useState(null);
  const [externalAnchor, setExternalAnchor] = useState(null);
  const [newCardAnchor, setNewCardAnchor] = useState(null);
  const [focus,setFocus] = useState(0);
  const [mobileMenu,setMobileMenu] = useState(false);
  const [globalOpen, setGlobalOpen] = useState(false);
  const [newGroupOpen, setNewGroupOpen] = useState(false);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [mouseDown, setMouseDown] = useState(false);

  const dispatch = useDispatch();
  const copiedBlocks = useSelector(state => state.data.copiedBlocks);

  useEffect(()=>{
    setName(card.name);
    setBlocks(card.blocks ? card.blocks : [])

    // document.addEventListener('copy', function(e) {
      // if(selectedRef.current.length){
      //   const sortedBlocks = selectedRef.current.sort(function(a,b){
      //     return a.index - b.index;
      //   })
      //   //copy blocks over onto internal clipboard
      //   const newBlocks = JSON.parse(JSON.stringify(sortedBlocks));
      //   copiedRef.current = newBlocks;
      //   dispatch(setCopiedBlocks(newBlocks))
      //   //string for system clipboard
      //   const output = stringifyBlocks(sortedBlocks)
      //   e.clipboardData.setData('text/plain', output);
      //   e.preventDefault();
      // }
    // });

    // document.addEventListener('cut', function(e) {
    //   if(selectedRef.current.length){
    //     const sortedBlocks = selectedRef.current.sort(function(a,b){
    //       return a.index - b.index;
    //     })
    //     const newBlocks = JSON.parse(JSON.stringify(sortedBlocks));
    //     copiedRef.current = newBlocks;
    //     dispatch(setCopiedBlocks(newBlocks))
    //     const output = stringifyBlocks(sortedBlocks)
    //     e.clipboardData.setData('text/plain', output);
    //     e.preventDefault();
    //     //remove blocks from card
    //     removeBlocks(newBlocks,blocksRef.current);
    //   }
    // });

    // document.addEventListener('paste', function(e) {
    //   if(copiedRef.current.length){
    //     e.preventDefault();
    //     insertBlocks(copiedRef.current,focusRef.current,blocksRef.current);
    //   }
    // });

    return function cleanUp(){
      setName('');
      setBlocks([]);
    }

  },[card.uid])

  const handleUpdate=(newBlocks,fetch)=>{
    setBlocks(newBlocks);
    updateBlocks(newBlocks,fetch);
  }

  const removeBlocks=(toRemove,blocks)=>{
    const newBlocks = JSON.parse(JSON.stringify(blocks));
    const filteredBlocks = newBlocks.filter(item => !toRemove.filter(remove => remove.id === item.id).length >0);
    handleUpdate(filteredBlocks,true);
  }

  const insertBlocks=(copies,focus,blocks)=>{
    const newCopies = JSON.parse(JSON.stringify(copies));
    newCopies.forEach(item =>
      item.id = uuidv4()
    )
    const newBlocks = JSON.parse(JSON.stringify(blocks));
    newBlocks.splice(focus,0,...newCopies);
    handleUpdate(newBlocks,true);
  }

  const handleName = async(ev)=>{
      const name = ev.target.value;
      setName(name);
      clearTimeout(window.cardNameTimeOut);
      window.cardNameTimeOut = setTimeout(function(){
        updateName(name);
      },interval);
  }

  const updateText = (value,index)=>{
    const newBlocks = blocks;
    newBlocks[index].value = value;
    handleUpdate(newBlocks,false);
  }

  const updateBlockType =(value,index)=>{
    const newBlocks = blocks;
    newBlocks[index].type = value;
    handleUpdate(newBlocks,false);
  }

  const updateBlockIndent =(value,index)=>{
    const newBlocks = blocks;
    newBlocks[index].indent = value;
    handleUpdate(newBlocks,false);
  }

  const updateBlock=(newBlock,index,fetch)=>{
    const newBlocks = blocks;
    newBlocks.splice(index,0,newBlock);
    handleUpdate(newBlocks,fetch);
  }

  const addBlock = (index)=>{
    const newBlocks = blocks;
    const newBlock={
      id: uuidv4(),
      value:'',
      type:'text'
    }
    newBlocks.splice(index,0,newBlock);
    handleUpdate(newBlocks,false);
    handleFocus(index)
  }

  const deleteBlock=(index)=>{
    const newBlocks = blocks;
    newBlocks.splice(index,1);
    handleUpdate(newBlocks,true);
    handleFocus(index-1)
  }

  const addInternal=(id,label)=>{
    const internal={
      id: uuidv4(),
      type:'internal',
      source:card.uid,
      target:id,
      value:label
    }
    updateBlock(internal,selectedIndex,true);
    setSelectedIndex(null);
  }

  const addInternalWithIndex=(id,label,index)=>{
    const internal={
      id: uuidv4(),
      type:'internal',
      source:card.uid,
      target:id,
      value:label
    }
    updateBlock(internal,index,true);
  }

  const addLink=(url,label)=>{
    const link={
      id: uuidv4(),
      type:'link',
      url:url,
      value:label
    }
    updateBlock(link,selectedIndex,true);
    setSelectedIndex(null);
  }

  const addGlobal=(id,label)=>{
    const globalLink={
      id: uuidv4(),
      type:'global',
      source:card.uid,
      target:id,
      value:label
    }
    updateBlock(globalLink,selectedIndex,true);
    setSelectedIndex(null);
  }

  const handleAddCard=async(name)=>{
    const targetId = await addCard(name,card.group,false,card.uid);
    const internal={
      id: uuidv4(),
      type:'internal',
      source:card.uid,
      target:targetId,
      value:name
    }
    updateBlock(internal,selectedIndex,false);
    setSelectedIndex(null);
  }

  const setMenu=(anchor,index)=>{
    setMenuAnchor(anchor);
    setSelectedIndex(index);
  }

  const handleMobileMenu=(anchor,index)=>{
    setMobileMenu(anchor);
    setSelectedIndex(index);
  }

  const handleMenuSelection=(value,anchor)=>{
    setMenuAnchor(null);
    switch (value) {
      case 'url':
        setExternalAnchor(anchor);
        break;
      case 'internal':
        setInternalAnchor(anchor);
        break;
      case 'h1':
        updateBlockType(value,selectedIndex);
        setSelectedIndex(null);
        break;
      case 'h2':
        updateBlockType(value,selectedIndex);
        setSelectedIndex(null);
        break;
      case 'global':
        setGlobalOpen(true);
        break;
      case 'bold':
        updateBlockType(value,selectedIndex);
        setSelectedIndex(null);
        break;
      default:
        setNewCardAnchor(anchor);
    }
  }

  const handleMenuClose=()=>{
    setMenuAnchor(null);
    setSelectedIndex(null);
  }

  const selectBlock=(block)=>{
    if(block.id){
      const newSelection = JSON.parse(JSON.stringify(selectedBlocks));
      if(newSelection.length === 0){
        let focusBlock = blocks[focus];
        focusBlock.index = focus;
        newSelection.push(focusBlock);
      }
      const match = newSelection.find(item => item.id === block.id);
      if(!match){
        const i = blocks.map(e => e.id).indexOf(block.id);
        if(i >-1){
          block.index = i;
          newSelection.push(block);
        }
      }
      setSelectedBlocks(newSelection);
    }
  }

  const clearSelectedBlocks=()=>{
    setSelectedBlocks([]);
  }

  const clearCopiedBlocks=()=>{
    dispatch(setCopiedBlocks([]));
  }

  const handleFocus=(value)=>{
    setFocus(value);
  }

  const copyBlocks=()=>{
    const sortedBlocks = selectedBlocks.sort(function(a,b){
      return a.index - b.index;
    })
    const newBlocks = JSON.parse(JSON.stringify(sortedBlocks));
    dispatch(setCopiedBlocks(newBlocks))
    return stringifyBlocks(sortedBlocks)
  }

  const pasteBlocks=()=>{
    insertBlocks(copiedBlocks,focus,blocks);
  }

  const cutBlocks=()=>{
    const sortedBlocks = selectedBlocks.sort(function(a,b){
      return a.index - b.index;
    })
    const newBlocks = JSON.parse(JSON.stringify(sortedBlocks));
    dispatch(setCopiedBlocks(newBlocks))
    removeBlocks(newBlocks,blocks);
    return stringifyBlocks(sortedBlocks)
  }

  const deleteBlocks=()=>{
    const sortedBlocks = selectedBlocks.sort(function(a,b){
      return a.index - b.index;
    })
    const newBlocks = JSON.parse(JSON.stringify(sortedBlocks));
    removeBlocks(newBlocks,blocks);
  }

  const blockComponents = blocks.map((block,index) => {
    switch(block.type){
      case 'internal':
        return <LinkBlock
        block={block}
        key={index}
        index={index}
        deleteBlock={deleteBlock}
        addBlock={addBlock}
        blockCount={blocks.length}
        updateBlock={updateBlock}
        profile={profile}
        mouseDown={mouseDown}
        selectBlock={selectBlock}
        setMouseDown={setMouseDown}
        selectedBlocks={selectedBlocks}
        />
      case 'link':
        return <LinkBlock
        block={block}
        key={index}
        index={index}
        deleteBlock={deleteBlock}
        addBlock={addBlock}
        blockCount={blocks.length}
        updateBlock={updateBlock}
        profile={profile}
        mouseDown={mouseDown}
        selectBlock={selectBlock}
        setMouseDown={setMouseDown}
        selectedBlocks={selectedBlocks}
        />
      case 'global':
        return <LinkBlock
        block={block}
        key={index}
        index={index}
        deleteBlock={deleteBlock}
        addBlock={addBlock}
        blockCount={blocks.length}
        updateBlock={updateBlock}
        />
      default:
        return <EditableBlock
        block={block}
        key={index}
        index={index}
        updateText={updateText}
        blockCount={blocks.length}
        addBlock={addBlock}
        deleteBlock={deleteBlock}
        setMenu={setMenu}
        focus={focus}
        setFocus={handleFocus}
        setSearchOpen={setSearchOpen}
        updateBlockType={updateBlockType}
        cards={cards}
        blocks={blocks}
        addInternal={addInternalWithIndex}
        cardId={card.id}
        profile={profile}
        updateBlockIndent={updateBlockIndent}
        selectBlock={selectBlock}
        selectedBlocks={selectedBlocks}
        clearSelectedBlocks={clearSelectedBlocks}
        mouseDown={mouseDown}
        setMouseDown={setMouseDown}
        copyBlocks={copyBlocks}
        pasteBlocks={pasteBlocks}
        cutBlocks={cutBlocks}
        copiedBlocks={copiedBlocks}
        deleteBlocks={deleteBlocks}
        clearCopiedBlocks={clearCopiedBlocks}
        />
    }
  });

  if(redirect){
    return(
      <Redirect exact to={'/card/'+redirect}/>
    )
  }

  return(
    <div className={classes.container}>
    <section className={classes.header}>
      <InputBase
      placeholder='Card Name'
      className={classes.name}
      value={name}
      onChange={handleName}
      multiline
      disabled={!profile}
      />
      {
        profile &&
        <CardMenu card={card} deleteCard={deleteCard}/>
      }
    </section>
    <section className={classes.group}>
      <GroupSelector updateGroup={updateGroup} card={card} groups={groups} setNewGroupOpen={setNewGroupOpen} profile={profile}/>
    </section>
    <section className={classes.blocks}>
      {blockComponents}
    </section>
    <Hidden smUp>
      <section className={classes.mobileBar}>
        <Button onClick={()=>{handleMobileMenu(true,focus)}}>
        Options
        </Button>
      </section>
    </Hidden>
    {
      !rightValue &&
      <div className={classes.actions}>
        <Fab
        size='small'
        style={{backgroundColor:'white'}}
        onClick={()=>{handleRightValue('backLinks')}}
        >
          <Link/>
        </Fab>
      </div>
    }
    <Drawer
    className={classes.rightDrawer}
    variant='persistent'
    anchor='right'
    classes={{
      paper: classes.rightDrawerPaper
    }}
    open={rightValue === 'backLinks' || rightValue === 'suggestions'}
    >
      {
        rightValue === 'backLinks' ?
        <BackLinkMenu handleRightValue={handleRightValue} card={card} cards={cards} backLinks={backLinks}/>
        :
        rightValue === 'suggestions' ?
        <SuggestionMenu handleRightValue={handleRightValue} card={card} cards={cards}/>
        :
        <div/>
      }
    </Drawer>
    <BlockOptions anchor={menuAnchor} setMenu={setMenu} handleMenuSelection={handleMenuSelection} handleClose={handleMenuClose}/>
    <InternalLinkPopover anchor={internalAnchor} setAnchor={setInternalAnchor} addLink={addInternal} cards={cards} addCard={handleAddCard}/>
    <ExternalLinkPopover anchor={externalAnchor} setAnchor={setExternalAnchor} addLink={addLink}/>
    <MobileBlockOptions open={mobileMenu} setMobileMenu={setMobileMenu} handleMenuSelection={handleMenuSelection} setSelectedIndex={setSelectedIndex}/>
    <NewGroupDialog open={newGroupOpen} setOpen={setNewGroupOpen} addGroup={addGroup}/>
    </div>
  )
}
