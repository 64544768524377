import React, {useState} from 'react';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
//utils
import {v4 as uuidv4} from 'uuid';

const useStyles = makeStyles((theme)=>({
  container:{
    padding:theme.spacing(4),
  },
  input:{
    width:'300px'
  }
}))

export default function NewWorkspaceDialog(props){

  const classes = useStyles();

  const [value, setValue] = useState('');

  const{
    open,
    setOpen,
    db,
    profile
  } = props;

  const handleClose=()=>{
    setValue('');
    setOpen(false);
  }

  const handleSubmit=()=>{
    const workspaceId = uuidv4();
    db.collection('Workspaces').doc(workspaceId).set({
      name:value,
      owner:profile.uid,
      categories:[
        {
          title:'None',
          enabled:true,
          color:'#dee3f2'
        }
      ]
    });
    db.collection('Profiles').doc(profile.uid).update({
      currentWorkspace: workspaceId
    });
    setValue('');
    setOpen(false);
  }

  const handleKeyDown=(ev)=>{
    if(ev.key === 'Enter' || ev.keyCode === '13'){
      ev.preventDefault();
      handleSubmit();
    }
  }

  return(
    <Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paper:classes.container
    }}
    >
      <DialogTitle>New Workspace</DialogTitle>
      <DialogContent>
        <TextField
        placeholder='Workspace Name'
        autoFocus
        value={value}
        onChange={(ev)=>{setValue(ev.target.value)}}
        onKeyDown={handleKeyDown}
        />
      </DialogContent>
    </Dialog>
  )
}
