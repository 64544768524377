const SET_PROFILE = 'SET_PROFILE';
const SET_AUTH_LOADED = 'SET_AUTH_LOADED';
const SET_WORKSPACE = 'SET_WORKSPACE';
const SET_GROUPS = 'SET_GROUPS';

export const setProfile = (profile)=>{
  return{
    type: SET_PROFILE,
    profile
  }
}

export const setAuthLoaded = (authLoaded)=>{
  return{
    type: SET_AUTH_LOADED,
    authLoaded
  }
}

export const setWorkspace = (workspace)=>{
  return{
    type: SET_WORKSPACE,
    workspace
  }
}

export const setGroups = (groups)=>{
  return{
    type: SET_GROUPS,
    groups
  }
}

const initialState={
  profile:null,
  authLoaded: false,
  workspace: null,
  groups: []
}

const userReducer=(state=initialState, action)=>{
  switch(action.type){
    case SET_PROFILE:
      return{
        ...state,
        profile:action.profile
      }
    case SET_AUTH_LOADED:
      return{
        ...state,
        authLoaded: action.authLoaded
      }
    case SET_WORKSPACE:
      return{
        ...state,
        workspace: action.workspace
      }
    case SET_GROUPS:
      return{
        ...state,
        groups: action.groups
      }
    default:
      return state
  }
}

export default userReducer;
