import React from 'react';
import {
  Button,
  Typography,
  Card
} from '@material-ui/core';
import google from '../images/google.png';
import style from './signInStyle';

export default function SigninView(props){
  return(
    <div id='container' style={style.container}>
      <Typography variant='h4' style={{marginBottom:'20px'}}>Join The Remix Beta</Typography>
      <Card style={style.form}>
        <Button
        onClick={props.handleSignInWithGoogle}
        variant='outlined'
        >
        <img src={google} alt={google} style={{height:'20px',width:'auto',marginRight:'10px'}}/>
        Sign In With Google
        </Button>
      </Card>
    </div>
  )
}
