import React from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
  container:{
    padding:theme.spacing(1),
  },
  autoComplete:{
    width:'300px'
  }
}))

export default function SearchDialog(props){
  const classes = useStyles();

  const{
    open,
    setOpen,
    cards,
    selectCard
  } = props;

  const handleClose=()=>{
    setOpen(false);
  }

  const handleChange=(option)=>{
    selectCard(option.id);
    handleClose();
  }

  return(
    <Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paper:classes.container
    }}
    >
    <DialogTitle>Search</DialogTitle>
    <DialogContent>
      <Autocomplete
      autoHighlight
      openOnFocus
      fullWidth
      options={cards}
      getOptionLabel={(option)=>option.name}
      className={classes.autoComplete}
      onChange={(event,newValue)=>handleChange(newValue)}
      renderInput={(params) => <TextField autoFocus{...params} />}
      />
    </DialogContent>
    </Dialog>
  )
}
