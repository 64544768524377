import React, {useCallback, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemIcon
} from '@material-ui/core';
import{
  Clear,
  CallMade
} from '@material-ui/icons';
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

const drawerWidth = 300;
const useStyles = makeStyles((theme)=>({
  rightDrawer:{
    width:drawerWidth,
    flexShrink:0,
    zIndex: theme.zIndex.appBar -1,
  },
  rightDrawerPaper:{
    borderTop:'1px solid lightgrey',
    width:drawerWidth,
    marginTop:theme.spacing(8),
    zIndex: theme.zIndex.appBar -1,
  },
  actions:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    marginLeft:theme.spacing(1)
  },
  title:{
    padding:theme.spacing(1)
  },
  content:{
    margin:theme.spacing(1),
    padding: theme.spacing(1),
    height:'70%',
    overflowY:'scroll',
  },
  backLinks:{
    marginLeft:theme.spacing(1),
    color:'grey'
  },
  nullSection:{
    marginLeft: theme.spacing(1)
  },
  link:{
    textDecoration:'none',
    color:theme.palette.primary.main
  },
  list:{
    overflowY:'auto',
    height:'25%',
  },
  group:{
    color:'grey'
  }
}));

export default function FilterRightDrawer(props){

  const classes = useStyles();

  const{
    open,
    setOpen,
    updateCategories,
  }=props;

  const workspace = useSelector(state => state.user.workspace);
  const categories = workspace ? workspace.categories : [];

  const handleCategory=(index,checked)=>{
    const newCategories = categories;
    newCategories[index].enabled = checked;
    updateCategories(newCategories);
  }

  return(
    <Drawer
    className={classes.rightDrawer}
    variant='persistent'
    anchor='right'
    classes={{
      paper: classes.rightDrawerPaper
    }}
    open={open}
    >
      <section className={classes.actions}>
        <IconButton onClick={()=>{setOpen(false)}}>
          <Clear/>
        </IconButton>
      </section>
      <section className={classes.title}>
        <Typography variant='h5'>Filter</Typography>
      </section>
      <section className={classes.content}>
      {
        categories.map((category,index)=>
          <ListItem key={index}>
            <ListItemIcon>
              <Checkbox
              edge='start'
              checked={category.enabled}
              onChange={(ev)=>{handleCategory(index,ev.target.checked)}}
              />
            </ListItemIcon>
            <ListItemText primary={category.title}/>
          </ListItem>
        )
      }
      </section>
    </Drawer>
  )
}
