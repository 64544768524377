import React, {useEffect, useState} from 'react';
import SignInView from './SignInView';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
  CircularProgress,
  Typography
} from '@material-ui/core';

export default function SignInContainer(props){

  const handleSignInWithGoogle=async()=>{
    try{
      const provider = new props.firebase.auth.GoogleAuthProvider();
      props.firebase.auth().signInWithRedirect(provider);
    }catch(err){
      if(err.message){
        alert(err.message);
      }
    }
  }

  const {db} = props;

  const profile = useSelector(state => state.user.profile);
  const authLoaded = useSelector(state => state.user.authLoaded);

  const [loaded, setLoaded] = useState(false);

  let unsubCard = null;

  useEffect(()=>{

    const listenCard=async(uid)=>{
      unsubCard = db.collection('Cards').where('owner','==',uid)
      .where('secret','==',true)
      .limit(1).onSnapshot(function(snapshot){
        if(!snapshot.empty){
          setLoaded(true);
        }
      })
    }

    if(profile){
      listenCard(profile.uid);
    }

    return function cleanUp(){
      if(unsubCard){
        unsubCard();
      }
    }

  },[profile]);

  if(profile && loaded){
    return <Redirect exact to='/'/>
  }

  if(!authLoaded){
    return (
      <div style={{width:'100%',height:'400px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <CircularProgress style={{marginTop:'40px'}}/>
      </div>
    )
  }

  if(profile){
    return (
      <div style={{width:'100%',height:'400px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <Typography variant='h6'>Loading Your Profile</Typography>
        <CircularProgress style={{marginTop:'40px'}}/>
      </div>
    )
  }

  return(
    <SignInView
    handleSignInWithGoogle={handleSignInWithGoogle}
    />
  )

}
