import React, {useState, useRef, useEffect} from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import SpriteText from 'three-spritetext';
import {Redirect} from 'react-router-dom';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import BlockRightDrawer from './BlockRightDrawer';
import FilterRightDrawer from './FilterRightDrawer';
import {
  Button,
  IconButton,
  Typography
} from '@material-ui/core';
import SearchDialog from '../main/SearchDialog';
import { makeStyles} from '@material-ui/core/styles';
import CardDialog from './CardDialog';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme)=>({
  searchButton:{
    position:'absolute',top:10,left:10,color:'white',
    border:'1px solid white',
  },
  typeToggle:{
    position:'absolute',top:10,right:400,
    border:'1px solid white',
    color:'white'
  },
  filterButton:{
    position:'absolute',top:15,right:300,
    border:'1px solid white',
    color:'white'
  }
}));

export default function EditGraphView(props){

  const classes = useStyles();

  const [selectedNode,setSelectedNode] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [graphData,setGraphData] = useState(null);

  const graphRef = useRef();

  const workspace = useSelector(state => state.user.workspace);
  const cards = useSelector(state => state.data.cards);
  const links = useSelector(state => state.data.links);
  const data = useSelector(state => state.data.data);

  const groups = workspace ? workspace.categories : [];

  const{
    handleRedirect,
    redirect,
    db,
    searchOpen,
    setSearchOpen,
    filterOpen,
    setFilterOpen,
    updateCategories,
    addCard,
    fetchCards,
    deleteCard,
  } = props;

  // useEffect(()=>{
  //   const bloomPass = new UnrealBloomPass();
  //   bloomPass.strength = 0.25;
  //   bloomPass.radius = 0.5;
  //   bloomPass.threshold = 0.5;
  //
  //   if(graphRef.current){
  //     graphRef.current.postProcessingComposer().addPass(bloomPass);
  //   }
  // },[data])

  useEffect(()=>{

    if(data){
      // console.log(data)
      setGraphData(data);
    }
  },[data])

  const focusOnNode=(node)=>{
    const distance = 300;
    const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

    graphRef.current.cameraPosition(
      { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
      node, // lookAt ({ x, y, z })
      2000  // ms transition duration
    );
  }

  const handleClick = (node)=>{
    if(selectedNode){
      if(selectedNode.id === node.id){
        handleRedirect(selectedNode.id);
      }else{
        setSelectedNode(node);
        // addLink(selectedNode,node.id);
      }
    }else{
      setSelectedNode(node);
    }
  };

  const handleHover=(node,prevNode)=>{
    setHoveredNode(node);
  }

  const handleSearch=(nodeId)=>{
    const selected = data.nodes.find(node => node.id === nodeId);
    if(selected){
      focusOnNode(selected);
      setSelectedNode(selected);
    }
  }

  const handleBackgroundClick=async(event)=>{
    if(selectedNode){
      setSelectedNode(null);
      // const cardId = await addCard(selectedNode.id,false);
      // if(cardId){
      //   handleRedirect(cardId);
      // }
    }
  }

  const renderName=(node)=>{
    const sprite = new SpriteText(node.name);
    sprite.color = 'white';
    // sprite.textHeight = 2 + Number(node.val*2);
    sprite.textHeight = 2 + Number(node.val);
    sprite.position.y = 8;
    return sprite;
  }

  // const handleVisibility=(node,filtered)=>{
  //   if(!selectedNode){return true};
  //   if(filtered.find(link => link.source === node.id || link.target === node.id)){
  //     return true;
  //   }
  //   return false;
  // }
  //
  // const handleLinkVisibility=(selectedLink,filtered)=>{
  //   if(!selectedNode){return true};
  //   if(filtered.find(link => link.source === selectedLink.source.id && link.target === selectedLink.target.id)){
  //     return true;
  //   }
  //   return false;
  // }

  const handleDragEnd=(node)=>{
    // node.fx = node.x;
    // node.fy = node.y;
    // node.fz = node.z;
  }

  const handleColor=(node)=>{
    const match = groups.find(item => item.title === node.group);
    if(match){
      return match.color;
    }
    return 'white';
  }

  const handleLinkWidth=(link)=>{
    if(hoveredNode){
      if(link.source.id === hoveredNode.id || link.target.id === hoveredNode.id){
        return 3;
      }
    }
    return 1;
  }

  if(redirect){
    return(
      <Redirect exact to={'/card/'+redirect}/>
    )
  }



  if(graphData){
    // let filteredLinks = [];
    // if(hoveredNode){
    //   filteredLinks = links.filter(link => link.source === hoveredNode.id || link.target === hoveredNode.id);
    // }

    return (
      <div style={{position:'relative'}}>
        <ForceGraph3D
          ref={graphRef}
          graphData={graphData}
          backgroundColor='#1f2227'
          nodeColor={node => handleColor(node)}
          onNodeClick={handleClick}
          onBackgroundClick={handleBackgroundClick}
          onNodeDragEnd={node => handleDragEnd(node)}
          nodeThreeObject={renderName}
          nodeThreeObjectExtend={true}
          linkDirectionalArrowLength={3}
          showNavInfo={false}
          linkDirectionalArrowRelPos={.7}
          onNodeHover={handleHover}
          linkWidth={handleLinkWidth}
        />
        {
          selectedNode &&
          <CardDialog
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          db={db}
          addCard={addCard}
          redirect={redirect}
          cards={cards}
          fetchCards={fetchCards}
          links={links}
          deleteCard={deleteCard}
          groups={groups}
          />
        }
        {
          filterOpen &&
          <FilterRightDrawer open={filterOpen} setOpen={setFilterOpen} updateCategories={updateCategories}/>
        }
        <SearchDialog open={searchOpen} setOpen={setSearchOpen} selectCard={handleSearch} cards={cards}/>
      </div>
    )
  }

  return(
    <div/>
  )

}
