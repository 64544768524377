import React from 'react';
import {
  TextField,
  Dialog,
  Popover
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from '@material-ui/lab';

const useStyles = makeStyles((theme)=>({
  container:{
    padding:theme.spacing(1),
  },
  autoComplete:{
    width:'300px'
  }
}))

export default function BlockOptions(props){
  const classes = useStyles();

  const{
    anchor,
    setMenu,
    handleMenuSelection,
    handleClose
  } = props;

  const handleChange=(option)=>{
    handleMenuSelection(option.value,anchor);
  }

  const options=[
    {
      label:'H1: Header 1',
      value:'h1'
    },
    {
      label:'H2: Header 2',
      value:'h2'
    },
    {
      label:'Bold',
      value:'bold'
    },
    {
      label:'Internal Link',
      value:'internal'
    },
    {
      label:'External Link',
      value:'url'
    },
  ]

  return(
    <Dialog
    open={Boolean(anchor)}
    onClose={handleClose}
    classes={{
      paper:classes.container
    }}

    >
        <Autocomplete
        autoHighlight
        openOnFocus
        options={options}
        getOptionLabel={(option)=>option.label}
        className={classes.autoComplete}
        onChange={(event,newValue)=>handleChange(newValue)}
        renderInput={(params) => <TextField autoFocus{...params} />}
        />
    </Dialog>
  )
}
