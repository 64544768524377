import React, {useState} from 'react';
import {
  Typography,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Collapse,
  List,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import {
  MoreHoriz
} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import {
  ChevronRight,
  ExpandMore
} from '@material-ui/icons';
import {parseChildren} from '../graph/graph';

const useStyles = makeStyles((theme)=>({
  link:{
    textDecoration:'none',
    color:'black'
  },
  container:{
    paddingLeft:'10px'
  },
  leftSpacer:{
    width:'40px'
  }
}))

export default function SideCardTile(props){

  const{
    card,
    deleteCard,
    cards,
    profile
  } = props;

  const classes = useStyles();

  const [anchor,setAnchor] = useState(null);
  const [open, setOpen] = useState(false);

  const handleMenuOpen=(ev)=>{
    setAnchor(ev.currentTarget);
  }

  const handleDelete=()=>{
    setAnchor(null);
    deleteCard(card.id);
  }

  const blocks = card.blocks ? card.blocks : [];
  let links =[];
  blocks.forEach(block=>{
    if(block.type === 'internal'){
      links.push(block);
    }
  })
  const filteredLinks = links.filter(link=>
    cards.find(card=>card.id === link.source) && cards.find(card=>card.id === link.target)
  )
  const linkedCards = filteredLinks.map(link =>{
    return cards.find(card => card.id === link.target);
  });

  let name = card.name ? card.name : 'Untitled';
  if(name.length > 20){
    name = name.substring(0,19)+ '...';
  }

  return(
    <div className={classes.container}>
    <ListItem button dense>
      {
        open ?
        <ListItemIcon>
        <IconButton size='small' onClick={()=>{setOpen(false)}}>
          <ExpandMore/>
        </IconButton>
        </ListItemIcon>
        :
        <ListItemIcon>
        <IconButton size='small' onClick={()=>{setOpen(true)}}>
          <ChevronRight/>
        </IconButton>
        </ListItemIcon>
      }
      <Link to={'/card/'+card.id} className={classes.link}>
        <Typography style={{fontSize:'14px'}}>{name}</Typography>
      </Link>
      {
        profile &&
        <ListItemSecondaryAction>
          <IconButton edge='end' size='small' onClick={handleMenuOpen}>
            <MoreHoriz/>
          </IconButton>
        </ListItemSecondaryAction>
      }
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List>
      {
        linkedCards.map((linkedCard,index) =>
          <SideCardTile card={linkedCard} cards={cards} key={index} deleteCard={deleteCard}/>
        )
      }
      </List>
    </Collapse>
    <Menu
    anchorEl={anchor}
    getContentAnchorEl={null}
    open={Boolean(anchor)}
    onClose={()=>{setAnchor(null)}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem onClick={handleDelete}>
      Delete
      </MenuItem>
    </Menu>
    </div>
  )
}
