import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper
} from '@material-ui/core';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
  paper:{
    padding: theme.spacing(3),
    width:'200px',
    height:'150px'
  }
}));

export default function GalleryTile(props){

  const classes = useStyles();

  const {workspace} = props;

  return(
    <Grid item>
      <Link to={'/graph/'+workspace.id} style={{textDecoration:'none'}}>
        <Paper className={classes.paper}>
          <Typography>{workspace.name}</Typography>
        </Paper>
      </Link>
    </Grid>
  )
}
