import React from 'react';
import{
  Typography,
  IconButton
} from '@material-ui/core';
import {
  Clear
} from '@material-ui/icons';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container:{

  },
  header:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    borderTop:'1px solid lightgrey'
  },
  headerText:{
    marginLeft: theme.spacing(1)
  }
}));

export default function SuggestionMenu(props){

  const classes = useStyles();

  const{
    handleRightValue
  } = props;

  return(
    <div className={classes.container}>
      <section className={classes.header}>
        <Typography variant='h6' className={classes.headerText}>Suggested Cards</Typography>
        <IconButton onClick={()=>{handleRightValue(null)}}>
          <Clear/>
        </IconButton>
      </section>
    </div>
  )
}
